import { Enum } from "helpers";
import { observer } from "mobx-react";
import { automationStore } from "stores";
import HeaderForm from "./HeaderForm";
import { RappidNodeType, RappidText } from "helpers/Constants";
import stepFolderIcon from "assets/img/automation/step-group.svg";
import { Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import SubmitButton from "./SubmitButton";
import { toJS } from "mobx";
import { useEffect } from "react";
import { EditRappidNode } from "components/Rappid/RappidCanvasFunctions";
import { validateNodeAndResetBorder } from "components/Rappid/RappidCanvasFunctions";

const FolderForm = observer(() => {
  const { AutomationType, TriggerList, TriggerListClient, AutomationStateType, FolderCategories, FolderCategory, UpdateFolderDestination } = Enum;
  const { formFolder, automationAudiences, automationType, automationState, triggerList, isCopiedTemplate } = automationStore;

  const isDisableButton = !formFolder?.folderCategoryId || !formFolder?.folderIds?.length || !formFolder?.updateFolderTo?.length;
  const isDraft = automationState === AutomationStateType.Draft;

  const ShowOnPlacement = [
    TriggerList.CandidatePlacementStartDate,
    TriggerList.CandidatePlacementEndDate,
    TriggerList.CandidatePlacementCreatedDate,
    TriggerList.PlacementJobApplicationReceived
  ];

  const ShowOnPlacementClient = [
    TriggerListClient.ClientPlacementCreatedDate,
    TriggerListClient.ClientPlacementStartDate,
    TriggerListClient.ClientPlacementEndDate
  ];

  const ShownOnApplicationAndJob = [
    ...ShowOnPlacement,
    TriggerList.ApplicationStatus,
    TriggerList.JobStatus,
    TriggerList.JobJobApplicationReceived,
    TriggerList.InterviewDate
  ];

  const ShowOnJobClient = [
    ...ShowOnPlacementClient,
    TriggerListClient.JobStatus,
    TriggerListClient.ClientJobInterviewDate
  ];

  const isPlacementTrigger = () =>
    triggerList.some(item =>
      automationType === AutomationType.Candidate
      ? ShowOnPlacement.includes(item.firstColumn.id)
      : ShowOnPlacementClient.includes(item.firstColumn.id)
    );

  const isApplicationTrigger = () =>
    triggerList.some(item =>
      automationType === AutomationType.Candidate
      ? ShownOnApplicationAndJob.includes(item.firstColumn.id)
      : [].includes(item.firstColumn.id)
    );

  const isJobTrigger = () =>
    triggerList.some(item =>
      automationType === AutomationType.Candidate
      ? ShownOnApplicationAndJob.includes(item.firstColumn.id)
      : ShowOnJobClient.includes(item.firstColumn.id)
    );

  useEffect(() => {
    const cloneFormFolder = { ...automationStore.formFolder };
    if (isCopiedTemplate === true && typeof cloneFormFolder.isUpdatedFromCopiedTemplate === "undefined") {
      cloneFormFolder.folderIds = [];
      cloneFormFolder.folderNames = "";

      automationStore.setFormFolder(cloneFormFolder, true);
      EditRappidNode(RappidNodeType.Folder, cloneFormFolder.name, "", "", "", cloneFormFolder, false, true);
    }

    // replace folder destination to only candidate when trigger is candidate and add folder
    if (!(isPlacementTrigger() || isApplicationTrigger())
      && formFolder.folderCategoryId === FolderCategory.Add
      && formFolder.updateFolderTo.some(item => [UpdateFolderDestination.Placement, UpdateFolderDestination.Application].includes(item))) {
      automationStore.setFormFolder({
        ...formFolder,
        updateFolderTo: [UpdateFolderDestination.CandidateContact]
      });
    }

    return function cleanup() {
      automationStore.resetFormFolder();
    }
  }, []);

  // Handle when operation (Add/Remove) selected
  const handleSelectedOperation = (e) => {
    if (formFolder.folderCategoryId === e.id) return;

    const isRemoveOperation = e.id === FolderCategory.Remove;
    const isAddOperation = e.id === FolderCategory.Add;
    const isCandidateContactTrigger = !(isPlacementTrigger() || isApplicationTrigger());

    const getUpdateFolderDestination = () => {
      // if remove folder, need to remove all
      if (isRemoveOperation) {
        return automationType === AutomationType.Candidate
          ? [UpdateFolderDestination.CandidateContact, UpdateFolderDestination.Placement, UpdateFolderDestination.Application]
          : [UpdateFolderDestination.CandidateContact, UpdateFolderDestination.Placement];
      }

      // set default folder destination for candidate trigger and add folder
      if (isAddOperation && isCandidateContactTrigger) {
        return [UpdateFolderDestination.CandidateContact];
      }

      return [];
    }

    automationStore.setFormFolder({
      ...formFolder,
      folderCategoryId: e.id,
      folderCategoryName: e.name,
      folderIds: [],
      folderNames: "",
      updateFolderTo: getUpdateFolderDestination()
    });
  };

  // Handle when folder(s) selected
  const handleSelectedFolder = (e) => {
    automationStore.setFormFolder({
      ...formFolder,
      folderIds: e.map(x => x.id),
      folderNames: e.map(x => x.name).join(',')
    });
  };

  // Handle when checkbox checked
  const handleCheckboxFolder = (e) => {
    const { value, checked: isChecked } = e.target;
    const updateFolderTo = isChecked
      ? [...formFolder.updateFolderTo, parseInt(value)]
      : formFolder.updateFolderTo.filter((item) => item !== parseInt(value));

    automationStore.setFormFolder({ ...formFolder, updateFolderTo: updateFolderTo });
  };

  const showCheckBox = formFolder.folderCategoryId !== FolderCategory.Remove && (isPlacementTrigger() || isApplicationTrigger());

  console.debug('Folder form data:', toJS(formFolder));

  return(
    <>
      <HeaderForm
        automationHeaderId="form-step-folder"
        automationHeaderImgSrc={stepFolderIcon}
        automationHeaderImgAlt="icon-folder"
        automationHeaderTextStep={RappidText.FolderStep}
      />

      <Row className="ml-0 mr-0 mb-4">
        <Col md={12} className="px-0">
          <Label id="folder-label" className="text-white">Add or Remove</Label>
          <UncontrolledTooltip placement="bottom" target="folder-label">
            Choose whether to add or remove people
          </UncontrolledTooltip>
        </Col>
        <Col md={12} className="px-0">
          <Select
            classNamePrefix="automation-dropdown"
            placeholder="- Select -"
            menuPlacement="auto"
            menuShouldBlockScroll={false}
            isDisabled={!isDraft}
            onChange={handleSelectedOperation}
            options={FolderCategories}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            value={FolderCategories.find(x => x.id === formFolder.folderCategoryId) || 0}
          />
        </Col>
      </Row>

      <Row className="ml-0 mr-0 mb-4">
        <Col md={12} className="px-0">
          <Label id="folder" className="text-white">Select Folder(s)</Label>
          <UncontrolledTooltip placement="bottom" target="folder">
            Choose one or more folders
          </UncontrolledTooltip>
        </Col>
        <Col md={12} className="px-0">
          <Select
            isMulti={true}
            className="automation-trigger-third-column automation-form-dropdown-multi"
            classNamePrefix="automation-dropdown"
            id={'folder-select'}
            name={'folder-select'}
            placeholder="- Select -"
            menuPlacement="auto"
            menuShouldBlockScroll={false}
            isDisabled={!isDraft}
            onChange={handleSelectedFolder}
            options={automationAudiences}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            value={formFolder?.folderIds?.length > 0 ? automationAudiences?.filter(x => formFolder?.folderIds?.includes(x.id)) : []}
          />
        </Col>
      </Row>

      {showCheckBox && <FormGroup className="step-body-text mb-2">
        <span className="mb-3">
          <b><Label className="text-white">
          Add {automationType === AutomationType.Candidate ? 'candidate' : 'contact'} to
          </Label></b>
        </span>
        <br/>
        <Input
          type="checkbox"
          checked={formFolder?.updateFolderTo?.includes(UpdateFolderDestination.CandidateContact)}
          id="folder-step-to-candidate"
          className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
          disabled={!isDraft}
          onChange={handleCheckboxFolder}
          value={UpdateFolderDestination.CandidateContact}
        />
        <Label check htmlFor={`folder-step-to-candidate`} className="mb-1 mr-3">
          {automationType === AutomationType.Candidate ? 'Candidate' : 'Contact'}
        </Label>

        {isPlacementTrigger() &&
        <>
          <Input
            type="checkbox"
            checked={formFolder?.updateFolderTo?.includes(UpdateFolderDestination.Placement)}
            id="folder-step-to-placement"
            className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
            disabled={!isDraft}
            onChange={handleCheckboxFolder}
            value={UpdateFolderDestination.Placement}
          />
          <Label check htmlFor={`folder-step-to-placement`} className="mb-1 mr-3">
            Placement
          </Label>
        </>
        }

        {isApplicationTrigger() &&
        <>
          <Input
            type="checkbox"
            checked={formFolder?.updateFolderTo?.includes(UpdateFolderDestination.Application)}
            id="folder-step-to-application"
            className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
            disabled={!isDraft}
            onChange={handleCheckboxFolder}
            value={UpdateFolderDestination.Application}
          />
          <Label check htmlFor={`folder-step-to-application`} className="mb-1 mr-3">
            Application
          </Label>
        </>
        }
      </FormGroup>}

      {isDraft &&
        <SubmitButton
          type={RappidNodeType.Folder}
          text={renderFolderNodeLine1(formFolder)}
          text2={renderFolderNodeLine2(formFolder)}
          roiData={formFolder}
          validateConditions={() => validateNodeAndResetBorder()}
          isDisabled={isDisableButton}
        />
      }
    </>
  )
});


export const renderFolderNodeLine1 = (formFolder) => `Action: ${formFolder?.folderCategoryName}`;

export const renderFolderNodeLine2 = (formFolder) => {
  const total = formFolder?.folderIds?.length ?? 0;
  return total < 2 ? `Total: ${total} folder` : `Total: ${total} folders`;
} 

export default FolderForm;