import { shapes, util } from "@clientio/rappid";
import { RappidNode, RappidText, RappidNodeType } from "helpers/Constants";
import { calculateAddDelayDate } from "helpers/AutomationHelper";
import { isEmpty } from "helpers/Validator";
import { automationReportStore, automationReportClientStore, automationStore, rappidStore } from "stores";
import * as _ from "lodash";
import { isStringEmpty, isEmptyArray } from "helpers/utilityFunctions";
import StepDelay from "assets/img/automation/step-delay-nobg.svg";
import StepEmail from "assets/img/automation/step-email-nobg.svg";
import StepCondition from "assets/img/automation/step-ifelse-nobg.svg";
import StepAlert from "assets/img/automation/step-alert-nobg.svg";
import StepUpdateRecord from "assets/img/automation/step-updaterecord-nobg.svg";
import StepText from "assets/img/automation/step-text-nobg.svg";
import StepFolder from "assets/img/automation/step-group-nobg.svg";
import IconDelete from "assets/img/automation/icon-delete.svg";
import { AutomationType, TriggerList, TriggerType,TriggerListClient } from "helpers/enum";
import { PrimaryColor } from "_variable";
import { toJS } from 'mobx';
import { renderConditionText, getTimeTriggerList, renderDelayText } from "components/Automation/SidebarForm/DelayForm";
import { renderTextNodeLine1, renderTextNodeLine2, renderTextNodeLine3 } from "components/Automation/SidebarForm/TextForm";
import { renderEmailNodeLine1, renderEmailNodeLine2, renderEmailNodeLine3, renderEmailNodeLine4 } from "components/Automation/SidebarForm/EmailForm";
import { renderAlertNodeLine1, renderAlertNodeLine2, renderAlertNodeLine3, renderAlertNodeLine4 } from "components/Automation/SidebarForm/AlertForm";
import { setUpdateRecordNodeTextLine1, setUpdateRecordNodeTextLine2 } from "components/Automation/SidebarForm/UpdateRecordForm";
import { Enum } from "helpers";
import { renderFolderNodeLine1, renderFolderNodeLine2 } from "components/Automation/SidebarForm/FolderForm";

//functions that add or remove nodes from the rappid canvas, nodes defined elsewhere

// Rappid Report Region
const UpdateCompletedStepText = (type = AutomationType.Candidate) => {
  const { graph } = rappidStore;
  const { candidateTriggered, clientTriggered, candidateSteps, clientSteps, triggerList } = type === AutomationType.Client ? automationReportClientStore : automationReportStore;
  const triggerStep = graph.getFirstCell();

  if (triggerList.length > 0) {
    const count = type === AutomationType.Client ? clientTriggered : candidateTriggered
    const descText = count === 1 ? RappidText.SingleCandidateEnteredText : RappidText.CandidateEnteredText;
    triggerStep.attr('additionalDescription/text', `${count} ${descText}`);
  }
  const successors = graph.getSuccessors(triggerStep) || [];

  if (candidateSteps && candidateSteps.length > 0) {
    successors.forEach(item => {
      const candidateStep = candidateSteps.find(x => x.stepId === item.id && x.stepType.toLowerCase() === item.attributes.roiNodeType.toLowerCase()) || {};
      const descText = candidateStep?.completedCandidatesCount === 1 ? RappidText.SingleCandidateEnteredText : RappidText.CandidateEnteredText;
      item.attr('additionalDescription/text', `${candidateStep?.completedCandidatesCount || 0} ${descText}`);
    });
  }

  if (clientSteps && clientSteps.length > 0) {
    successors.forEach(item => {
      const clientStep = clientSteps.find(x => x.stepId === item.id && x.stepType.toLowerCase() === item.attributes.roiNodeType.toLowerCase()) || {};
      const descText = clientStep?.completedClientsCount === 1 ? RappidText.SingleCandidateEnteredText : RappidText.CandidateEnteredText;
      item.attr('additionalDescription/text', `${clientStep?.completedClientsCount || 0} ${descText}`);
    });
  }

  // Filter ifelse elements only
  const ifElseElements = successors.filter(x => x.attributes.roiNodeType === RappidNodeType.IfElse) || [];
  ifElseElements.forEach(item => {
    const childs = graph.getNeighbors(item, { outbound: true }) || [];
    if (childs.length === 1) {  //TODO: If we add a final step all items will have 1 child
      const connectedLinks = graph.getConnectedLinks(item, { outbound: true }) || [];
      connectedLinks.length > 0 && connectedLinks[0].set({
        defaultLabel: {
          ...connectedLinks[0].get('defaultLabel'),
          position: {
            distance: 35,
            args: {
              absoluteDistance: true
            }
          }
        }
      });
    }
  });

  // Filter delay elements Only there is bug on 5796 when delay at first but in graph !== firstCell
  const cells = graph.getCells() || [];
  const delayElements = cells.filter(x => x.attributes.roiNodeType === RappidNodeType.Delay) || [];

  delayElements.forEach(item => {
    // [NOTE] reshuffle the position and change default description
    item.attr('additionalDescription/refY', '85%');
    item.attr('additionalDescription/text', `0 ${RappidText.CandidateEnteredText}`);
    item.attr('label/refY', '25%');
    item.attr('description/refY', '45%');
    item.attr('description2/refY', '65%');

    if (clientSteps && clientSteps.length > 0) {
      const element = clientSteps.find(x => x.stepId === item.id && x.stepType.toLowerCase() === item.attributes.roiNodeType.toLowerCase()) || {};
      const descText = element?.completedClientsCount === 1 ? RappidText.SingleCandidateEnteredText : RappidText.CandidateEnteredText;
      item.attr('additionalDescription/text', `${element?.completedClientsCount || 0} ${descText}`);
    }

    if (candidateSteps && candidateSteps.length > 0) {
      const element = candidateSteps.find(x => x.stepId === item.id && x.stepType.toLowerCase() === item.attributes.roiNodeType.toLowerCase()) || {};
      const descText = element?.completedCandidatesCount === 1 ? RappidText.SingleCandidateEnteredText : RappidText.CandidateEnteredText;
      item.attr('additionalDescription/text',
        `${element?.completedCandidatesCount || 0} ${descText}`
      );
    }
  });

  const textElements = cells.filter(x => x.attributes.roiNodeType === RappidNodeType.Text) || [];

  textElements.forEach(item => {
    item.attr('label/refY', '25%');
    item.attr('description/refY', '45%');
    item.attr('description2/refY', '63%');
    item.attr('additionalDescription/refY', '85%');
  });

};
// End of Rappid Report Region

const CheckIfRappidCanvasIsEmpty = (isUpdateForm = false) => {
  const { graph } = rappidStore;
  const successors = graph?.getSuccessors(graph.getFirstCell());
  const countValidateAutomationType = 1;
  return isUpdateForm && !automationStore.isAutomationFormDirty ? false : successors ? successors.length === countValidateAutomationType : true;
};

const AddRappidLink = (source, target, dashedLine = false) => {
  const { graph } = rappidStore;
  const link = new shapes.standard.Link();
  link.attr({
    line: {
      stroke: dashedLine ? RappidNode.DarkGreyColor : RappidNode.DarkColor,
      targetMarker: 'none',
      ...(dashedLine && { strokeDasharray: '5 5' }), // Set strokeDasharray only if dashedLine is true
    },
  });
  link.source(source);
  link.target(target);
  graph.addCells(link);
  return link;
};

const AddRappidLinkBranching = (source, target, text = RappidText.YesStep) => {
  const { graph } = rappidStore;
  const link = new shapes.Roi.CustomLinkBranching();
  link.attr({
    line: {
      stroke: RappidNode.DarkColor,
      targetMarker: 'none'
    },
  });
  link.source(source);
  link.target(target);
  link.appendLabel({
    attrs: {
      text: {
        text: text.toUpperCase(),
      },
      body: {
        stroke: text === RappidText.YesStep ? RappidNode.YesColor : RappidNode.NoColor,
        fill: text === RappidText.YesStep ? RappidNode.YesColor : RappidNode.NoColor
      },
      label: {
        fill: text === RappidNode.DarkColor
      },
    }
  });
  graph.addCells(link);
  return link;
}

const CheckIfFirstTriggerCanvasIsEmpty = () => isEmpty(rappidStore.graph?.getFirstCell().attributes?.roiData);
const IsAddStepLastStep = (activeAddedStepElement) => rappidStore.graph.getSuccessors(activeAddedStepElement).length === 0;

const AddEmptyTriggerNodeToCanvas = (isDrawInitStep = true, roiData = {}, isDraft = true) => {
  const { graph, paper } = rappidStore;
  const { isCopiedTemplate } = automationStore;
  let deleteIcon = {
    xlinkHref: '',
    'ref-dx': '',
    'ref-y': '',
    roiId: '',
    event: ''
  };
  let strokeDasharray = '10, 4';
  let descriptionText = "";

  if (!_.isEmpty(roiData)) {
    deleteIcon = {
      xlinkHref: IconDelete,
      'ref-dx': '-15%',
      'ref-y': '5%',
      roiId: `${RappidNode.DeleteStep}-${Date.now()}`,
      event: 'element:deletetrigger:pointerdown'
    };
    strokeDasharray = 'none';
    descriptionText = wrapText(roiData?.triggerName);
  }

  const initTriggerStep = new shapes.Roi.CustomTrigger({});
  initTriggerStep.attr('description/text', descriptionText);
  initTriggerStep.attr('body/strokeDasharray', strokeDasharray);
  initTriggerStep.attr('deleteIcon', deleteIcon);
  initTriggerStep.set('roiData', roiData);

  graph.addCells(initTriggerStep);
  paper.findViewByModel(initTriggerStep).$el.addClass('joint-type-roi-audience');
  return isDrawInitStep ? AddToCanvas_AddStepNode(true, null, null, null, null, initTriggerStep, null, isDraft) : initTriggerStep;
};

// NB: The position of the AddStepNOde is set in AddRappidLinkBranching /AddRappidLink, values passed in here are irrelevant
const AddToCanvas_AddStepNode = (
  isInitialStep = false,
  positionX,
  positionY,
  nodeWidth,
  nodeHeight,
  sourceNode,
  branchingText,
  isDraft = true
) => {
  const { graph, paper } = rappidStore;

  var rappidStep = GetAddStepNode(isInitialStep, positionX, positionY, nodeWidth, nodeHeight, isDraft);

  const previousNode = sourceNode ?? graph.getElements()[graph.getElements().length - 1];
  graph.addCells(rappidStep);

  paper.findViewByModel(rappidStep).$el.addClass('joint-type-roi-addstep');

  if (previousNode) {
    if (previousNode.attributes.roiNodeType === RappidNodeType.IfElse) {
      const text = branchingText ?? RappidText.YesStep;
      AddRappidLinkBranching(previousNode, rappidStep, text);
    } else
      AddRappidLink(previousNode, rappidStep);
  }
  return rappidStep;
};

const AddToCanvas_EndOfFlowNode = (
  positionX,
  positionY,
  sourceNode,
) => {
  const { graph } = rappidStore;
  const x = positionX ?? RappidNode.InitX;
  const y = positionY ?? RappidNode.InitY + RappidNode.DistanceY;
  const width = 150;
  const height = 39;
  const rappidStep = new shapes.standard.Rectangle({
    position: { x, y },
    size: { width, height },
    attrs: {
      label: {
        text: "End of flow",
        fill: RappidNode.GreyColor,
        fontWeight: 600,
        letterSpacing: 1,
        fontSize: 14,
      },
      body: {
        fill: RappidNode.WhiteColor,
        strokeWidth: 0,
        stroke: RappidNode.GreyColor,
        rx: 10,
        ry: 10,
      },
    },
    id: `${RappidNode.EndOfFlow}-${Date.now()}`,
    roiId: `${RappidNode.EndOfFlow}-${Date.now()}`,
    roiNodeType: RappidNode.EndOfFlow
  });

  const previousNode = sourceNode ?? graph.getElements()[graph.getElements().length - 1];
  graph.addCells(rappidStep);
  AddRappidLink(previousNode, rappidStep, true);
  return rappidStep;
};

const GetAddStepNode = (isInitialStep, positionX, positionY, nodeWidth, nodeHeight, isDraft) => {
  const x = positionX ?? RappidNode.InitX;
  const y = positionY ?? RappidNode.InitY + RappidNode.DistanceY;
  const width = nodeWidth ?? isDraft ? RappidNode.AddStepWidth : 0;
  const height = nodeHeight ?? isDraft ? RappidNode.AddStepHeight : 0;

  if (isDraft) {
    return new shapes.standard.Rectangle({
      position: { x, y },
      size: { width, height },
      attrs: {
        label: {
          text: RappidText.AddStep,
          fill: RappidNode.WhiteColor
        },
        body: {
          fill: RappidNode.PrimaryColor,
          strokeWidth: 0,
          stroke: PrimaryColor,
          rx: 10,
          ry: 10,
        },
      },
      id: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
      roiId: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
      roiNodeType: RappidNode.AddStep
    });
  } else {
    return new shapes.standard.Rectangle({
      position: { x, y },
      size: { width, height },
      id: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
      roiId: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
      roiNodeType: RappidNode.AddStep
    });
  }
}

const RedrawCanvas = () => {
  const { graph, paperScroller, tree } = rappidStore;
  const graphCells = graph.getCells();
  graph.clear();
  graph.addCells(graphCells);
  tree.layout();
  paperScroller.positionElement(graph.getFirstCell(), 'top')
  if (graph.getFirstCell())
    paperScroller.scrollToElement(graph.getFirstCell());
};

const setEditTriggerText = (triggerNode, isDraft) => {
  if (!triggerNode) return;
  triggerNode.attr('label/text', 'EDIT TRIGGER');
  triggerNode.attr('description/text', RappidText.HoverInformation);
  triggerNode.attr('label/refY', '33%');
  triggerNode.attr('body/strokeDasharray', 'none');
  if (isDraft === true) {
    triggerNode.attr({
      deleteIcon: {
        xlinkHref: IconDelete,
        'ref-dx': '-15%',
        'ref-y': '5%',
        roiId: `${RappidNode.DeleteStep}-${Date.now()}`,
        event: 'element:deletetrigger:pointerdown'
      }
    });
  }
  rappidStore.paper.findViewByModel(triggerNode).$el.addClass('joint-type-roi-customtrigger');
}

const EditRappidNode = (roiNodeType, text, text2, text3, text4, roiData, isResetActiveElement = true, isClearTemplateData = false) => {
  roiData = roiData || {};
  const { activeElement } = rappidStore;
  const { isCopiedTemplate, automationState } = automationStore;

  const isDraft = automationState === Enum.AutomationStateType.Draft;

  if (roiNodeType !== RappidNodeType.IfElse) {
    var nodeData = {
      description: text,
      description2: text2,
      description3: text3,
      description4: text4
    };

    setTextPositionOnCustomNode(activeElement, nodeData, roiNodeType);
  }

  if (isCopiedTemplate && !isClearTemplateData) {
    activeElement?.attr('body/strokeWidth', RappidNode.InactiveElementBorderStrokeWidth);
    activeElement?.attr('body/stroke', RappidNode.WhiteColor);
    if (roiNodeType === RappidNodeType.Delay) {
      activeElement?.attr('body/stroke', RappidNode.DarkColor);
    }
  }

  activeElement?.attr('description/text', wrapText(text));
  activeElement?.attr('description2/text', truncateText(text2));
  activeElement?.attr('description3/text', truncateText(text3));
  activeElement?.attr('description4/text', truncateText(text4));
  activeElement?.set('roiNodeType', roiNodeType);
  activeElement?.set('roiData', roiData);

  if (roiNodeType === RappidNodeType.Trigger)
    setEditTriggerText(activeElement, isDraft);
  if (roiNodeType === RappidNodeType.Delay && GetDelayId(activeElement, false)) { // calculate delay date if updated node in the middle or top of the tree
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure({ type: 'edit', editedDelayNode: activeElement }));
  } else
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure());

  if (roiNodeType === RappidNodeType.Delay) {
    if (roiData.isFirstDelay && automationStore.triggerType !== TriggerType.PlacementBased) {
      activeElement?.attr('label', { text: RappidText.DelayScheduleStep });
      activeElement?.set('label', { text: RappidText.DelayScheduleStep });
    } else {
      activeElement?.attr('label', { text: RappidText.DelayStep });
      activeElement?.set('label', { text: RappidText.DelayStep });
    }
    if (roiData.isDelayCondition)
      activeElement?.attr('description2/text', truncateText(text2, 60));
  }
  isResetActiveElement && rappidStore.resetActiveElement();
};

const DeleteNode = (deletedNode) => {
  const { graph, tree, deletingNode } = rappidStore;
  const { isCopiedTemplate } = automationStore;
  rappidStore.setDeletingNode(deletedNode);

  if (!_.isEmpty(deletingNode)) {
    const nextDelayNodeId = GetDelayId(deletedNode, false);
    const deletedNodeId = deletedNode?.id;
    const previousAddStepNode = graph.getNeighbors(deletedNode, { inbound: true });
    const previousAutomationNode = graph.getNeighbors(previousAddStepNode[0], { inbound: true });
    if (deletedNode?.attributes?.roiNodeType === RappidNodeType.IfElse) {
      graph.removeCells(graph.getSuccessors(deletedNode)); //remove all nodes below deleted node
      graph.removeCells(deletedNode);// remove deleted node
      // re-add end of flow node, unless last step was custom trigger
      if (_.cloneDeep(previousAutomationNode[0]).attributes.type !== RappidNode.RoiCustomTrigger) {
        if (previousAddStepNode.length > 0) {
          let position = previousAddStepNode[0].position();
          AddToCanvas_EndOfFlowNode(RappidNode.DistanceX, position.y + RappidNode.DistanceY, previousAddStepNode[0]);
        };
      }
    } else {
      const nextStep = graph.getNeighbors(deletedNode, { outbound: true });
      const nextAutomation = graph.getNeighbors(nextStep[0], { outbound: true });
      const deletedNodeLinks = graph.getConnectedLinks(deletedNode);
      const deletedStepLinks = graph.getConnectedLinks(nextStep[0], { outbound: true });
      graph.removeCells(deletedNode, nextStep[0], deletedNodeLinks, deletedStepLinks); //only remove the deletedNode, the rest of the nodes are reattached to the previous node
      AddRappidLink(previousAddStepNode[0], nextAutomation[0]); //add links between previous node chain and next node chain
      // if the last node is end of flow and the previous node is custom trigger, remove the end of flow node
      if (_.cloneDeep(nextAutomation[0]).attributes.roiNodeType === RappidNodeType.EndOfFlow && _.cloneDeep(previousAutomationNode[0]).attributes.type === RappidNode.RoiCustomTrigger)
        graph.removeCells(nextAutomation);
    }
    tree.layout();
    rappidStore.setDeletingNode({}); // set data to null for hide modal delete
    rappidStore.resetActiveElement();
    automationStore.setShowSideBar(false);
    automationStore.setShowSideBarForm(false);
    automationStore.resetSidebarForm();

    if (deletedNode?.attributes?.roiNodeType === RappidNodeType.Delay && nextDelayNodeId) { // calculate delay date if deleted node in the middle or top of the tree
      automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure({ type: 'delete', deletedNodeId: deletedNodeId }));
      rappidStore.resetActiveElement();
    } else
      automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure());

    if (isCopiedTemplate) {
      automationStore.isAutomationUpdatedFromTemplate();
    }
  }
};

const DeleteTrigger = (deletedNode, isWithoutConfirmation = false) => {  // TODO: Combine with AddEmptyTriggerStepToCanvas
  const { deletingNode, paper } = rappidStore;
  const { isCopiedTemplate } = automationStore;
  const automationTrigger = JSON.parse(ConvertTriggerListForBackEnd());
  const candidatePlacement = automationTrigger.find(el => el?.firstColumn?.id === TriggerList.CandidatePlacementStartDate);

  if (!isWithoutConfirmation) rappidStore.setDeletingNode(deletedNode);
  if (!_.isEmpty(deletingNode) || isWithoutConfirmation) {
    deletedNode.set('roiNodeType', RappidNodeType.Trigger);
    deletedNode?.attr('label/text', RappidText.TriggerStep);
    deletedNode?.attr('label/refY', '50%');
    deletedNode.set('roiData', {});
    deletedNode.attr('description/text', '');
    deletedNode.attr('description2/text', '');
    deletedNode.attr('body/strokeDasharray', '10, 4');
    deletedNode.attr({
      deleteIcon: {
        xlinkHref: '',
        'ref-dx': '',
        'ref-y': '',
        roiId: '',
        event: ''
      }
    });
    rappidStore.setDeletingNode({}); // set data to null for hide modal delete

    if (candidatePlacement) ResetAlertPlacementOwner();
    if (!_.isEmpty(deletingNode)) {
      automationStore.setShowSideBar(false);
      automationStore.setShowSideBarForm(false);
      paper.findViewByModel(deletingNode).$el.removeClass('joint-type-roi-customtrigger'); // ADD this class for used in ui.Tooltip conditional
    }
    if (isWithoutConfirmation)
      paper.findViewByModel(deletedNode).$el.removeClass('joint-type-roi-customtrigger'); // ADD this class for used in ui.Tooltip conditional

    automationStore.setTriggerType(null);
    automationStore.setTriggerList([]);
    automationStore.setAutomationAudiencesIds([]);
    automationStore.resetSelectedAutomationAudienceIds();
    automationStore.setAutomationUserIds([]);
    automationStore.resetSelectedAutomationUserIds();
    automationStore.setAutomationOfficeIds([]);
    automationStore.resetSelectedAutomationOfficeIds();
    automationStore.setAutomationGroupIds([]);
    automationStore.resetSelectedAutomationGroupIds();
    automationStore.setIncludeRecentNoteOwner(true);

    if (isCopiedTemplate) {
      automationStore.isAutomationUpdatedFromTemplate();
    }
  }
};

const ResetAlertPlacementOwner = () => {
  const { graph } = rappidStore;
  const triggerStep = graph.getFirstCell();
  const automationSteps = graph.getSuccessors(triggerStep).filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
  automationSteps.forEach(node => {
    if (node.attributes.roiNodeType === RappidNodeType.Alert)
      node?.set('roiData', { ...node.attributes.roiData, isSendToPlacementOwner: false });
  });
}

const loadTriggerNodeData = (triggerList, isReport = false, isDraft = true) => {
  const { isCopiedTemplate } = automationStore;
  const elements = rappidStore.graph.getElements();
  if (!isEmptyArray(triggerList) && !isReport) {    //indicate if trigger has been set.
    setEditTriggerText(elements[0], isDraft);
    //NB: This is a terrible hack, setting form trigger roi data to trigger list because the trigger form loads the data from the roi data, of the trigger
    //TODO: fix the trigger form to load the data from the trigger list, not here, or quicker still redo app...  

    var isValid = validateIsListUpdated(triggerList);

    if (isCopiedTemplate && !isValid) {
      elements[0].attr('body/strokeWidth', RappidNode.ErrorElementBorderStrokeWidth);
      elements[0].attr('body/stroke', RappidNode.TemplateErrorColor);
    }

  }
  elements[0].set('roiData', toJS(triggerList));
}

const validateIsListUpdated = (list) => {
  var isValid = true;
  var parsedList = list;

  if (Object.keys(parsedList).length < 1) {
    isValid = false;
    return;
  }



  if (!Array.isArray(parsedList)) {
    parsedList = JSON.parse(list);
  }

  parsedList.forEach(data => {
    if (data.isUpdatedFromCopiedTemplate !== true) isValid = false;
  });

  return isValid;
}

const removePreviousEndOFlowNode = (activeElement) => {
  const { graph } = rappidStore;
  if (activeElement) {
    const activeElementCell = graph.getCell(activeElement.id);
    const connectedNodesBelowActiveElement = graph.getNeighbors(activeElementCell, { outbound: true, });
    connectedNodesBelowActiveElement.forEach((node) => {
      if (_.cloneDeep(node).attributes.roiNodeType === RappidNodeType.EndOfFlow) // deep copy required to check attributes.roiNodeType 
        node.remove();
    });
  }
}

const AddNodeToCanvas = (roiNodeType, nodeText, text2, text3, text4, roiData, passedAddedStepElement, id, isUserAddedNode, isDraft) => {
  roiData = roiData || {};
  const { graph, paper, tree, paperScroller, activeElement } = rappidStore;
  const activeAddedStepElement = passedAddedStepElement || activeElement;
  let { x, y } = activeAddedStepElement.attributes.position; //NB : possibly remove, not used for positioning add step anymore. Positioning is done in AddRappidLinkBranching /AddRappidLink
  y = y + RappidNode.DistanceY;
  x = x - RappidNode.DistanceX;

  removePreviousEndOFlowNode(activeAddedStepElement);

  const isAddStepLastStep = IsAddStepLastStep(activeAddedStepElement);
  const nodeData = setNodeData(roiNodeType, nodeText, text2, text3, text4, roiData);
  const newNode = createNewNode(roiNodeType, roiData, null, null, nodeData, activeAddedStepElement, isUserAddedNode, false, isDraft);

  if (id) {
    newNode.set('id', id); //set id to be the same if data loaded 
  }
  graph.addCells(newNode);;
  let addStepNode;

  //after adding new node, add the add step button/s
  // NB: The position of the AddStepNOde is set in AddRappidLinkBranching /AddRappidLink, values passed in here are irrelevant
  if (roiNodeType === RappidNodeType.IfElse) {
    addStepNode = AddToCanvas_AddStepNode(false, x + RappidNode.DistanceX - RappidNode.BranchingX, y + RappidNode.DistanceY, null, null, newNode, RappidText.YesStep, isDraft);
    if (isAddStepLastStep) // don't add end of flow node if we are inserting ifelse node in the middle of automation, only add if we are adding at the end
      AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, addStepNode);
    let secondAddStepNode = AddToCanvas_AddStepNode(false, x + RappidNode.DistanceX + RappidNode.BranchingX, y + RappidNode.DistanceY, null, null, newNode, RappidText.NoStep, isDraft);
    AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, secondAddStepNode);
    paper.findViewByModel(newNode).$el.addClass('joint-type-roi-customnode-ifelse');  // add this new class for if-else, this class is used in ui.Tooltip
  } else {
    addStepNode = AddToCanvas_AddStepNode(false, x + RappidNode.DistanceX, y + RappidNode.DistanceY, null, null, newNode, null, isDraft);
    if (isAddStepLastStep)
      AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, addStepNode); // add end of flow node if not last step
  }

  if (!isAddStepLastStep) {
    const nextElements = graph.getNeighbors(activeAddedStepElement, { outbound: true }) || [];
    const nextLines = graph.getConnectedLinks(activeAddedStepElement, { outbound: true }) || [];
    graph.removeCells(nextLines);
    AddRappidLink(addStepNode, nextElements[0]);
  }
  AddRappidLink(activeAddedStepElement, newNode);

  if (!isAddStepLastStep && roiNodeType === RappidNodeType.Delay) // calculate delay date if added node in the middle or top of the tree
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure({ type: 'add', newDelayNode: newNode }));
  else
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure());

  rappidStore.resetActiveElement();
  tree.layout();
  paperScroller.scrollToElement(newNode);
  return newNode;
};

function GetElementToLinkTo(currentElementGUID, arrayStructureData) {
  let elementToLinkTo = null;
  arrayStructureData.forEach((element) => {
    if (element.nextYes === currentElementGUID)
      elementToLinkTo = { id: element.step, isYes: true };
    if (element.nextNo === currentElementGUID)
      elementToLinkTo = { id: element.step, isYes: false, isYesEmpty: element.nextYes === null };
  });
  return elementToLinkTo;
}

const getAddStepNodeToAttachTo = (index, arrayStructure) => {
  const { graph } = rappidStore;
  let elements = graph.getElements();
  let data = JSON.parse(arrayStructure);

  const lastElement = elements[elements.length - 1];
  if (lastElement.attributes.roiNodeType !== RappidNodeType.EndOfFlow) return lastElement; // in the case that trigger node is only present the last element is not endOfFlow

  //to ensure we are linking to the correct element we check the array data and match the current id
  let currentElementArrayData = data[index];
  let currentElementId = currentElementArrayData.step; //step in arraydata is the id of the element in elements
  let elementWeShouldLinkTo = GetElementToLinkTo(currentElementId, data);
  if (!elementWeShouldLinkTo) return null;

  //search current list of elements on the canvas and get the current node we should link to
  let ElementWithId = elements.find((element) => element.id === elementWeShouldLinkTo.id);
  let indexOfElementWithId = elements.indexOf(ElementWithId);

  if (elementWeShouldLinkTo.isYes) {
    return elements[indexOfElementWithId + 1];
  }

  if (elementWeShouldLinkTo.isYesEmpty) {
    return elements[indexOfElementWithId + 3];
  } else {
    return elements[indexOfElementWithId + 2];
  }
}

const loadAutomationIntoCanvas = (arrayStructure, triggerList, automationState, redrawCanvas) => {
  const { graph, paperScroller, tree } = rappidStore;

  const isDraft = automationState === Enum.AutomationStateType.Draft;

  if (redrawCanvas) {
    graph.clear();
    AddEmptyTriggerNodeToCanvas(true, {}, isDraft);
  }

  loadTriggerNodeData(triggerList, false, isDraft);
  const data = JSON.parse(arrayStructure);
  data.forEach((element, index) => {
    let lineOfText1 = "";
    let lineOfText2 = "";
    let lineOfText3 = "";
    let lineOfText4 = "";
    switch (element.type) {
      case "email":
        lineOfText1 = renderEmailNodeLine1(element.roiData);
        lineOfText2 = renderEmailNodeLine2(element.roiData);
        lineOfText3 = renderEmailNodeLine3(element.roiData);
        lineOfText4 = renderEmailNodeLine4(element.roiData);
        break;
      case "text":
        lineOfText1 = renderTextNodeLine1(element.roiData.title);
        lineOfText2 = renderTextNodeLine2(element.roiData);
        lineOfText3 = renderTextNodeLine3(element.roiData);
        break;
      case "delay":
        getTimeTriggerList(automationStore.triggerList, automationStore.automationType, automationStore);
        lineOfText1 = renderDelayText(automationStore.isTriggerPlacementStartDate, automationStore.isTriggerPlacementEndDate, automationStore.automationState, element);
        lineOfText2 = renderConditionText(element.roiData?.delayCondition?.thirdColumn?.names);
        break;
      case "alert":
        lineOfText1 = renderAlertNodeLine1(element.roiData);
        lineOfText2 = renderAlertNodeLine2(element.roiData);
        lineOfText3 = renderAlertNodeLine3(element.roiData);
        lineOfText4 = renderAlertNodeLine4(element.roiData);
        break;
      case "updateRecord":
        lineOfText1 = setUpdateRecordNodeTextLine1(element.roiData);
        lineOfText2 = setUpdateRecordNodeTextLine2(element.roiData);
        break;
      case "ifelse":
        lineOfText1 = RappidText.HoverInformation;
        break;
      case "folder":
        lineOfText1 = renderFolderNodeLine1(element.roiData);
        lineOfText2 = renderFolderNodeLine2(element.roiData);
        break;
      default:
    }

    var nodeToAttachTo = getAddStepNodeToAttachTo(index, arrayStructure);
    if (nodeToAttachTo)
      AddNodeToCanvas(element.type, lineOfText1, lineOfText2, lineOfText3, lineOfText4, element.roiData, nodeToAttachTo, element.step, false, isDraft);
  });

  tree.layout();
  paperScroller.scrollToElement(graph.getFirstCell());
}

const GetDelayId = (node = null, isPreviousDelay = true) => {
  const previousNode = node?.graph?.getNeighbors(node, isPreviousDelay ? { inbound: true } : { outbound: true });
  if (!previousNode) return ""; // return "" if no neighbor again
  return previousNode[0]?.attributes?.roiNodeType === RappidNodeType.Delay ? previousNode[0]?.id : GetDelayId(previousNode[0], isPreviousDelay);
}

const ResetDelayDate = () => { // reset delay node if load automation with status not active
  automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure({ type: 'reset' }));
  rappidStore.resetActiveElement();
}

const ConvertToDelayArrayStructure = (delayData = null) => {
  const { graph } = rappidStore;
  const triggerStep = graph.getFirstCell();
  const automationSteps = graph.getSuccessors(triggerStep).filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
  const arrayStructure = [];
  const newDelayNode = delayData?.type === "add" ? delayData.newDelayNode : null;
  const editedDelayNode = delayData?.type === "edit" ? delayData.editedDelayNode : null;
  const deletedNodeId = delayData?.type === "delete" ? delayData.deletedNodeId : null;
  let isStartEdit = false;

  automationSteps.forEach((node, index) => {
    let objectStructure = {};
    objectStructure.order = index + 1;
    objectStructure.step = node.attributes.id;
    objectStructure.type = node.attributes.roiNodeType;
    objectStructure.roiData = node.attributes.roiData;
    // update previous delay id & delay date
    if (node.attributes.roiNodeType === RappidNodeType.Delay && delayData) {
      if (delayData.type === "add") {
        if (node?.id === newDelayNode?.id || isStartEdit) {
          let prevDelayNode = null;
          let startDate = null;

          if (arrayStructure.length !== 0) {
            if (isStartEdit && !node?.attributes?.roiData?.previousDelayId) { // condition for if add delay node on the top of the tree
              prevDelayNode = arrayStructure.find(el => el?.step === GetDelayId(node));
            } else if (isStartEdit && node?.attributes?.roiData?.previousDelayId === newDelayNode?.attributes?.roiData?.previousDelayId) { // condition for if add delay node in the middle of the tree
              prevDelayNode = arrayStructure.find(el => el?.step === newDelayNode?.id);
            } else // for the updated children delay node
              prevDelayNode = arrayStructure.find(el => el?.step === node?.attributes?.roiData?.previousDelayId);
            startDate = prevDelayNode?.roiData?.delayDate;
          }

          const estimationDate = calculateAddDelayDate(
            node.attributes.roiData?.weeks,
            node.attributes.roiData?.days,
            node.attributes.roiData?.hours,
            node.attributes.roiData?.minutes,
            startDate
          );

          const roiData = {
            ...node.attributes.roiData,
            delayDate: estimationDate,
            previousDelayId: prevDelayNode?.step,
            previousTotalMinutesTime: (prevDelayNode?.roiData?.previousTotalMinutesTime || 0) + (node.attributes?.roiData?.weeks * 10080) + (node.attributes?.roiData?.days * 1440) + (node.attributes?.roiData?.hours * 60) + node.attributes?.roiData?.minutes
          };
          node?.set('roiData', roiData);
          objectStructure.roiData = { ...roiData };
          isStartEdit = true;
        }
      } else if (delayData.type === "edit") {
        if (isStartEdit || node?.id === editedDelayNode?.id) {
          let prevDelayNode = null;
          let startDate = null;

          if (node?.attributes?.roiData?.previousDelayId) { // if edit first delay -> first delay node doesn't have previous delay id
            prevDelayNode = arrayStructure.find(el => el?.step === node?.attributes?.roiData?.previousDelayId);
            startDate = prevDelayNode?.roiData?.delayDate;
          }

          const estimationDate = calculateAddDelayDate(
            node.attributes.roiData?.weeks,
            node.attributes.roiData?.days,
            node.attributes.roiData?.hours,
            node.attributes.roiData?.minutes,
            startDate
          );

          const roiData = {
            ...node.attributes.roiData,
            delayDate: estimationDate,
            previousTotalMinutesTime: (prevDelayNode?.roiData?.previousTotalMinutesTime || 0) + (node.attributes?.roiData?.weeks * 10080) + (node.attributes?.roiData?.days * 1440) + (node.attributes?.roiData?.hours * 60) + node.attributes?.roiData?.minutes
          };
          node?.set('roiData', roiData);
          objectStructure.roiData = { ...roiData };
          isStartEdit = true;
        }
      } else if (delayData.type === "delete") {
        if (isStartEdit || node?.attributes?.roiData?.previousDelayId === deletedNodeId) {
          const prevDelayNodeId = node?.attributes?.roiData?.previousDelayId === deletedNodeId ? GetDelayId(node) : node?.attributes?.roiData?.previousDelayId;
          let prevDelayNode = null;
          let startDate = null;

          if (prevDelayNodeId) {
            prevDelayNode = arrayStructure.find(el => el?.step === prevDelayNodeId);
            startDate = prevDelayNode?.roiData?.delayDate;
          }

          const estimationDate = calculateAddDelayDate(
            node.attributes.roiData?.weeks,
            node.attributes.roiData?.days,
            node.attributes.roiData?.hours,
            node.attributes.roiData?.minutes,
            startDate
          );

          const roiData = {
            ...node.attributes.roiData,
            delayDate: estimationDate,
            previousDelayId: prevDelayNodeId,
            previousTotalMinutesTime: (prevDelayNode?.roiData?.previousTotalMinutesTime || 0) + (node.attributes?.roiData?.weeks * 10080) + (node.attributes?.roiData?.days * 1440) + (node.attributes?.roiData?.hours * 60) + node.attributes?.roiData?.minutes
          };
          node?.set('roiData', roiData);
          objectStructure.roiData = { ...roiData };

          isStartEdit = true;
        }
      } else if (delayData.type === "reset") {
        let prevDelayNode = null;
        let startDate = null;

        if (node?.attributes?.roiData?.previousDelayId) {
          prevDelayNode = arrayStructure.find(el => el?.step === node?.attributes?.roiData?.previousDelayId);
          startDate = prevDelayNode?.roiData?.delayDate;
        }

        const estimationDate = calculateAddDelayDate(
          node.attributes.roiData?.weeks,
          node.attributes.roiData?.days,
          node.attributes.roiData?.hours,
          node.attributes.roiData?.minutes,
          startDate
        );

        const roiData = { ...node.attributes.roiData, delayDate: estimationDate };
        node?.set('roiData', roiData);
        objectStructure.roiData = { ...roiData };
      }
    }
    arrayStructure.push(objectStructure);
  });

  return JSON.stringify(arrayStructure);
}

const ResetCanvasDelayDate = () => {
  const { graph } = rappidStore;
  const triggerStep = graph.getFirstCell();
  const automationSteps = graph.getSuccessors(triggerStep).filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
  const arrayStructure = [];

  automationSteps.forEach((node, index) => {
    let objectStructure = {};
    objectStructure.order = index + 1;
    objectStructure.step = node.attributes.id;
    objectStructure.type = node.attributes.roiNodeType;
    objectStructure.roiData = node.attributes.roiData;

    if (node.attributes.roiNodeType === RappidNodeType.Delay) {
      let prevDelayNode = null;
      let startDate = null;

      if (node?.attributes?.roiData?.previousDelayId) {
        prevDelayNode = arrayStructure.find(el => el?.step === node?.attributes?.roiData?.previousDelayId);
        startDate = prevDelayNode?.roiData?.delayDate;
      }

      const estimationDate = calculateAddDelayDate(
        node.attributes.roiData?.weeks,
        node.attributes.roiData?.days,
        node.attributes.roiData?.hours,
        node.attributes.roiData?.minutes,
        startDate
      );

      const roiData = { ...node.attributes.roiData, delayDate: estimationDate };
      node?.set('roiData', roiData);
      objectStructure.roiData = { ...roiData };
    }

    arrayStructure.push(objectStructure);
  });

  return JSON.stringify(graph.toJSON());
}

const ConvertToArrayStructure = () => {
  const { graph } = rappidStore;
  const triggerStep = graph.getFirstCell();
  const automationSteps = graph.getSuccessors(triggerStep).filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
  const arrayStructure = [];

  automationSteps.forEach((node, index) => {
    let objectStructure = {};
    objectStructure.order = index + 1;
    objectStructure.step = node.attributes.id;
    objectStructure.type = node.attributes.roiNodeType;
    objectStructure.roiData = node.attributes.roiData;

    if (node.attributes.roiNodeType === RappidNodeType.IfElse) {
      const ifElseOptionNodes = graph.getNeighbors(node, { outbound: true }); // index 0 = YES, index 1 = NO
      objectStructure.nextYes = FilterNextStep(graph.getSuccessors(ifElseOptionNodes[0])[0]?.attributes.id);
      objectStructure.nextNo = FilterNextStep(graph.getSuccessors(ifElseOptionNodes[1])[0]?.attributes.id);
      objectStructure.roiData.forEach(element => {
        delete element.isShowWithinLast;
        delete element.firstColumn.options;
        delete element.secondColumn.options;
        delete element.thirdColumn.options;
      });
    } else
      objectStructure.nextYes = FilterNextStep(graph.getSuccessors(node)[1]?.attributes.id);

    arrayStructure.push(objectStructure);
  });
  automationStore.resetAutomationSidebarFormDirty();
  automationStore.setShowSideBar(false);
  automationStore.setShowSideBarForm(false);
  automationStore.resetSidebarForm();
  automationStore.setSidebarFormType("");
  rappidStore.resetActiveElement();

  return JSON.stringify(arrayStructure);
};

const FilterNextStep = (attributeId) => {
  return attributeId.startsWith('endOfFlow-') ? null : attributeId;
}

const GetArrayStructure = () => {
  const { graph } = rappidStore;
  const triggerStep = graph.getFirstCell();
  const automationSteps = graph.getSuccessors(triggerStep).filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
  const arrayStructure = [];

  automationSteps.forEach((node, index) => {
    let objectStructure = {};
    objectStructure.order = index + 1;
    objectStructure.step = node.attributes.id;
    objectStructure.type = node.attributes.roiNodeType;
    objectStructure.roiData = node.attributes.roiData;

    if (node.attributes.roiNodeType === RappidNodeType.IfElse) {
      const ifElseOptionNodes = graph.getNeighbors(node, { outbound: true }); // index 0 = YES, index 1 = NO
      objectStructure.nextYes = graph.getSuccessors(ifElseOptionNodes[0])[0]?.attributes.id ?? null;
      objectStructure.nextNo = graph.getSuccessors(ifElseOptionNodes[1])[0]?.attributes.id ?? null;
    } else
      objectStructure.nextYes = graph.getSuccessors(node)[1]?.attributes.id ?? null;

    arrayStructure.push(objectStructure);
  });

  return JSON.stringify(arrayStructure);
};

const ConvertTriggerListForBackEnd = () => {
  const { triggerList } = automationStore;
  let triggerListData = [...triggerList];
  // deleting unused key
  triggerListData.forEach(element => {
    delete element.firstColumn.options;
    delete element.secondColumn.options;
    delete element.thirdColumn.options;
    delete element.thirdColumn.activityOptions;
    delete element.thirdColumn.clientActivityOptions;
    delete element.thirdColumn.jobSources;
    if (element.firstColumn.id > 13 &&
      element.firstColumn.id !== TriggerList.CandidatePlacementEndDate &&
      element.firstColumn.id !== TriggerList.CandidatePlacementCreatedDate &&
      element.firstColumn.id !== TriggerList.EmailAddress &&
      element.firstColumn.id !== TriggerList.MobileNumber &&
      element.firstColumn.id !== TriggerList.Birthday &&
      element.firstColumn.id !== TriggerList.ApplicationStatus &&
      element.firstColumn.id !== TriggerList.JobStatus &&
      element.firstColumn.id !== TriggerList.InterviewDate &&
      element.firstColumn.id !== TriggerList.Availability &&
      element.firstColumn.id !== TriggerList.JobJobApplicationReceived &&
      element.firstColumn.id !== TriggerList.PlacementJobApplicationReceived &&
      element.firstColumn.id !== TriggerList.DateCreated &&
      element.firstColumn.id !== TriggerList.DateUpdated &&
      element.firstColumn.id !== TriggerList.CandidateCountry &&
      element.firstColumn.id !== TriggerList.SecondaryEmailAddress &&
      element.firstColumn.id !== TriggerList.SurveyScore &&
      element.firstColumn.id !== TriggerListClient.SurveyScore &&
      element.firstColumn.id !== TriggerList.PhoneNumber) {
      element.firstColumn.id = 13;  /* [NOTE] set all custom field id is 13 */
    }
  });

  return JSON.stringify(triggerListData);
}


const createNewNode = (roiNodeType, roiData, positionX = null, positionY = null, nodeData = {}, node = null, isUserAddedNode = false, isReport = false, isDraft = false) => {
  roiData = roiData || {};
  const { activeElement } = rappidStore;
  const { isCopiedTemplate } = automationStore;
  let { x = 0, y = 0 } = node !== null ? node.attributes.position : activeElement.attributes.position;
  y = positionY ?? y + RappidNode.DistanceY;
  x = positionX ?? x - RappidNode.DistanceX;

  let createdNode;
  if (roiNodeType === RappidNodeType.IfElse) {
    createdNode = new shapes.Roi.CustomBranching();
  } else if (roiNodeType === RappidNodeType.Delay) {
    createdNode = new shapes.Roi.CustomDelayNode();
  } else
    createdNode = new shapes.Roi.CustomNode();

  if (roiNodeType !== RappidNodeType.IfElse) //custom nodes or delay nodes
    setTextPositionOnCustomNode(createdNode, nodeData, roiNodeType);

  if (!isDraft)
    createdNode.attr('deleteIcon/xlinkHref', null);

  createdNode.attr('label/text', nodeData?.label);
  createdNode.attr('description/text', wrapText(nodeData?.description));
  createdNode.attr('description2/text', truncateText(nodeData?.description2));
  createdNode.attr('description3/text', truncateText(nodeData?.description3));
  createdNode.attr('description4/text', truncateText(nodeData?.description4));
  createdNode.attr('typeIcon/xlinkHref', nodeData?.icon);
  createdNode.set('roiNodeType', roiNodeType);
  createdNode.set('roiData', roiData);
  createdNode.set({ position: { x, y } });


  if (isCopiedTemplate && !isUserAddedNode) {
    var isValid = true;
    if (roiNodeType !== RappidNodeType.IfElse) {
      isValid = roiData.isUpdatedFromCopiedTemplate === true
    } else {
      isValid = validateIsListUpdated(roiData);
    }

    if (!isValid && !isReport) {
      createdNode.attr('body/strokeWidth', RappidNode.ErrorElementBorderStrokeWidth);
      createdNode.attr('body/stroke', RappidNode.TemplateErrorColor);
    }
  }

  return createdNode;
};

const setNodeData = (roiNodeType, nodeText, text2, text3, text4, roiData = null) => {
  let nodeData = {
    icon: StepEmail,
    backgroundColor: RappidNode.WhiteColor,
    label: RappidText.EmailStep,
    description: nodeText,
    description2: text2,
    description3: text3,
    description4: text4
  };

  switch (roiNodeType) {
    case RappidNodeType.Email:
      nodeData = { ...nodeData, icon: StepEmail, label: RappidText.EmailStep };
      break;
    case RappidNodeType.Delay:
      nodeData = {
        ...nodeData,
        icon: StepDelay,
        label: (automationStore.triggerType !== TriggerType.PlacementBased && roiData.isFirstDelay ? RappidText.DelayScheduleStep : RappidText.DelayStep),
        description2: truncateText(text2, 60)
      };
      break;
    case RappidNodeType.Alert:
      nodeData = { ...nodeData, icon: StepAlert, label: RappidText.AlertStep };
      break;
    case RappidNodeType.IfElse:
      nodeData = { ...nodeData, icon: StepCondition, label: RappidText.IfElseStep };
      break;
    case RappidNodeType.UpdateRecord:
      nodeData = { ...nodeData, icon: StepUpdateRecord, label: RappidText.UpdateRecordStep };
      break;
    case RappidNodeType.Text:
      nodeData = { ...nodeData, icon: StepText, label: RappidText.TextStep };
      break;
    case RappidNodeType.Folder:
      nodeData = { ...nodeData, icon: StepFolder, label: RappidText.FolderStep };
      break;
    default:
      break;
  }

  return nodeData;
};

const setTextPositionOnCustomNode = (node, nodeData, roiNodeType) => {
  switch (numberOfLinesOfTextInNode(nodeData)) {
    case 1:
      node.attr('label/refY', '40%');
      node.attr('description/refY', '65%');
      break;
    case 2:
      if (roiNodeType === RappidNodeType.Delay) {      //if delay node has 2 lines of text, style differently as delay node is smaller than custom node
        node.attr('label/refY', '30%');
        node.attr('description/refY', '55%');
        node.attr('description2/refY', '75%');
      }
      else {
        node.attr('label/refY', '32%');
        node.attr('description/refY', '52%');
        node.attr('description2/refY', '68%');
      }
      break;
    case 3:
      node.attr('label/refY', '25%');
      node.attr('description/refY', '45%');
      node.attr('description2/refY', '61%');
      node.attr('description3/refY', '77%');
      break;
    case 4:
      node.attr('label/refY', '25%');
      node.attr('description/refY', '45%');
      node.attr('description2/refY', '59%');
      node.attr('description3/refY', '74%');
      node.attr('description4/refY', '88%');
      break;
    default:
      break;
  }
};

function numberOfLinesOfTextInNode(nodeData) {
  let lastNotEmpty = 0;
  if (!isStringEmpty(nodeData?.description)) lastNotEmpty = 1;
  if (!isStringEmpty(nodeData?.description2)) lastNotEmpty = 2;
  if (!isStringEmpty(nodeData?.description3)) lastNotEmpty = 3;
  if (!isStringEmpty(nodeData?.description4)) lastNotEmpty = 4;
  return lastNotEmpty;
}

const wrapText = (text = "", width = RappidNode.RectWidth, height = RappidNode.RectHeight, fontSize = RappidNode.DescriptionFontSize, ellipsis = true) =>
  util.breakText(text, { width, height }, { 'font-size': fontSize }, { ellipsis });

const truncateText = (str, n = 72) => {
  if (str === null || str === undefined) return "";
  return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

const loadAutomationReportIntoCanvas = (arrayStructure, triggerList) => {
  const { graph, paperScroller } = rappidStore;
  loadTriggerNodeData(triggerList, true, false);
  const data = JSON.parse(arrayStructure);
  data.forEach((element, index) => {
    let lineOfText1 = "";
    let lineOfText2 = "";
    let lineOfText3 = "";
    let lineOfText4 = "";
    switch (element.type) {
      case "email":
        lineOfText1 = renderEmailNodeLine1(element.roiData);
        lineOfText2 = renderEmailNodeLine2(element.roiData);
        lineOfText3 = renderEmailNodeLine3(element.roiData);
        lineOfText4 = renderEmailNodeLine4(element.roiData);
        break;
      case "text":
        lineOfText1 = renderTextNodeLine1(element.roiData.title);
        lineOfText2 = renderTextNodeLine2(element.roiData);
        lineOfText3 = renderTextNodeLine3(element.roiData);
        break;
      case "delay":
        getTimeTriggerList(automationStore.triggerList, automationStore.automationType, automationStore);
        lineOfText1 = renderDelayText(automationStore.isTriggerPlacementStartDate, automationStore.isTriggerPlacementEndDate, automationStore.automationState, element);
        lineOfText2 = renderConditionText(element.roiData?.delayCondition?.thirdColumn?.names);
        break;
      case "alert":
        lineOfText1 = renderAlertNodeLine1(element.roiData);
        lineOfText2 = renderAlertNodeLine2(element.roiData);
        lineOfText3 = renderAlertNodeLine3(element.roiData);
        lineOfText4 = renderAlertNodeLine4(element.roiData);
        break;
      case "updateRecord":
        lineOfText1 = setUpdateRecordNodeTextLine1(element.roiData);
        lineOfText2 = setUpdateRecordNodeTextLine2(element.roiData);
        break;
      case "ifelse":
        lineOfText1 = RappidText.HoverInformation;
        break;
      default:
    }

    var nodeToAttachTo = getAddStepNodeToAttachTo(index, arrayStructure);
    if (nodeToAttachTo)
      AddNodeToReportCanvas(element.type, lineOfText1, lineOfText2, lineOfText3, lineOfText4, element.roiData, nodeToAttachTo, element.step);
  });

  paperScroller.scrollToElement(graph.getFirstCell());
}

const AddNodeToReportCanvas = (roiNodeType, nodeText, text2, text3, text4, roiData, passedAddedStepElement, id) => {
  roiData = roiData || {};
  const { graph, paper, tree, paperScroller, activeElement } = rappidStore;
  const activeAddedStepElement = passedAddedStepElement || activeElement;
  let { x, y } = activeAddedStepElement.attributes.position; //NB : possibly remove, not used for positioning add step anymore. Positioning is done in AddRappidLinkBranching /AddRappidLink
  y = y + RappidNode.DistanceY;
  x = x - RappidNode.DistanceX;

  removePreviousEndOFlowNode(activeAddedStepElement);

  const isAddStepLastStep = IsAddStepLastStep(activeAddedStepElement);
  const nodeData = setNodeData(roiNodeType, nodeText, text2, text3, text4, roiData);
  const newNode = createNewNode(roiNodeType, roiData, null, null, nodeData, activeAddedStepElement, false, true);
  if (id) {
    newNode.set('id', id); //set id to be the same if data loaded 
  }
  graph.addCells(newNode);;
  let addStepNode;

  //after adding new node, add the add step button/s
  // NB: The position of the AddStepNOde is set in AddRappidLinkBranching /AddRappidLink, values passed in here are irrelevant
  if (roiNodeType === RappidNodeType.IfElse) {
    addStepNode = AddToReportCanvas_AddStepNode(false, x + RappidNode.DistanceX - RappidNode.BranchingX, y + RappidNode.DistanceY, newNode, RappidText.YesStep);

    if (isAddStepLastStep) // don't add end of flow node if we are inserting ifelse node in the middle of automation, only add if we are adding at the end
      AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, addStepNode);

    let secondAddStepNode = AddToReportCanvas_AddStepNode(false, x + RappidNode.DistanceX + RappidNode.BranchingX, y + RappidNode.DistanceY, newNode, RappidText.NoStep);
    AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, secondAddStepNode);

    paper.findViewByModel(newNode).$el.addClass('joint-type-roi-customnode-ifelse');  // add this new class for if-else, this class is used in ui.Tooltip
  } else {
    addStepNode = AddToReportCanvas_AddStepNode(false, x + RappidNode.DistanceX, y + RappidNode.DistanceY, newNode);
    if (isAddStepLastStep)
      AddToCanvas_EndOfFlowNode(x + RappidNode.DistanceX, y + RappidNode.DistanceY, addStepNode); // add end of flow node if not last step
  }

  if (!isAddStepLastStep) {
    const nextElements = graph.getNeighbors(activeAddedStepElement, { outbound: true }) || [];
    const nextLines = graph.getConnectedLinks(activeAddedStepElement, { outbound: true }) || [];
    graph.removeCells(nextLines);
    AddRappidLink(addStepNode, nextElements[0]);
  }
  AddRappidLink(activeAddedStepElement, newNode);

  if (!isAddStepLastStep && roiNodeType === RappidNodeType.Delay) // calculate delay date if added node in the middle or top of the tree
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure({ type: 'add', newDelayNode: newNode }));
  else
    automationStore.setAutomationDelayArrayStructure(ConvertToDelayArrayStructure());

  rappidStore.resetActiveElement();
  tree.layout();
  //this one
  paperScroller.scrollToElement(newNode);
  return newNode;
};

const AddToReportCanvas_AddStepNode = (
  isInitialStep = false,
  positionX,
  positionY,
  sourceNode,
  branchingText,
) => {
  const { graph, paper } = rappidStore;
  const x = positionX ?? RappidNode.InitX;
  const y = positionY ?? RappidNode.InitY + RappidNode.DistanceY;
  const width = 0;
  const height = 0;
  const rappidStep = new shapes.standard.Rectangle({
    position: { x, y },
    size: { width, height },
    id: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
    roiId: isInitialStep ? RappidNode.InitStep : `${RappidNode.AddStep}-${Date.now()}`,
    roiNodeType: RappidNode.AddStep
  });

  const previousNode = sourceNode ?? graph.getElements()[graph.getElements().length - 1];

  graph.addCells(rappidStep);

  paper.findViewByModel(rappidStep).$el.addClass('joint-type-roi-addstep');

  if (previousNode) {
    if (previousNode.attributes.roiNodeType === RappidNodeType.IfElse) {
      const text = branchingText ?? RappidText.YesStep;
      AddRappidLinkBranching(previousNode, rappidStep, text);
    } else
      AddRappidLink(previousNode, rappidStep);
  }
  return rappidStep;
};

const AddEmptyTriggerNodeToReportCanvas = (isDrawInitStep = true, roiData = {}) => {
  const { graph, paper } = rappidStore;
  let deleteIcon = {
    xlinkHref: '',
    'ref-dx': '',
    'ref-y': '',
    roiId: '',
    event: ''
  };

  const initTriggerStep = new shapes.Roi.CustomTrigger({});
  initTriggerStep.attr('description/text', '');
  initTriggerStep.attr('deleteIcon', deleteIcon);
  initTriggerStep.set('roiData', roiData);
  graph.addCells(initTriggerStep);
  paper.findViewByModel(initTriggerStep).$el.addClass('joint-type-roi-audience');

  return isDrawInitStep ? AddToReportCanvas_AddStepNode(true, null, null, initTriggerStep) : initTriggerStep;
};

const getTriggerNodeData = () => {
  const { graph } = rappidStore;
  if(!graph) return []; 
  const triggerStep = graph.getFirstCell();
  return triggerStep.attributes.roiData;
}
const getAllNodes = () => {
  const { graph } = rappidStore;
  if(!graph) return [];
  const triggerStep = graph.getFirstCell();
  // Get all successors of trigger step that are automation steps (excludes utility nodes)
  return graph.getSuccessors(triggerStep)
    .filter(node => node.attributes.roiId.startsWith(RappidNode.AutomationStep)) || [];
};

const getNodesInfo = () => {
  const nodes = getAllNodes();
  return nodes.map((node, index) => ({
    order: index + 1,
    id: node.attributes.id,
    type: node.attributes.roiNodeType,
    data: node.attributes.roiData,
    hasError: nodeHasErrorBorder(node)
  }));
};


const setBorderColorForNode = (nodeId, color, width = 2) => {
  const { graph } = rappidStore;
  if (!graph) return false;
  const node = graph.getCell(nodeId);
  if (!node) return false;

  node.attr('body/stroke', color);
  node.attr('body/strokeWidth', width);

  return true;
};

// Helper function to reset border to default
const resetBorderForNode = (nodeId) => {
  const { graph } = rappidStore;
  if (!graph) return false;
  const node = graph.getCell(nodeId);
  
  if (!node) return false;

  // Get default values based on node type
  const defaultColor = node.attributes.roiNodeType === RappidNodeType.Delay ? 
    RappidNode.DarkColor : 
    RappidNode.WhiteColor;

  const defaultWidth = 0;

  return setBorderColorForNode(nodeId, defaultColor, defaultWidth);
};

const setErrorBorder = (nodeId) => {
  return setBorderColorForNode(nodeId, RappidNode.TemplateErrorColor, RappidNode.ErrorElementBorderStrokeWidth);
};

const nodeHasErrorBorder = (node) => {
  const borderColor = node.attr('body/stroke');
  return borderColor === RappidNode.TemplateErrorColor;
};

const validateNodeAndResetBorder = () => {
  const { node } = rappidStore;
  if (!node) return false;
  if (nodeHasErrorBorder(node)) {
    resetBorderForNode(node.id);
    return true;
  }
  return false;
};

export {
  UpdateCompletedStepText,
  AddNodeToCanvas,
  EditRappidNode,
  CheckIfRappidCanvasIsEmpty,
  CheckIfFirstTriggerCanvasIsEmpty,
  GetDelayId,
  ConvertToArrayStructure,
  ConvertToDelayArrayStructure,
  ConvertTriggerListForBackEnd,
  DeleteNode,
  AddEmptyTriggerNodeToCanvas,
  DeleteTrigger,
  RedrawCanvas,
  ResetCanvasDelayDate,
  ResetDelayDate,
  GetArrayStructure,
  loadAutomationIntoCanvas,
  loadAutomationReportIntoCanvas,
  AddEmptyTriggerNodeToReportCanvas,
  getAllNodes,
  getNodesInfo,
  setBorderColorForNode,
  resetBorderForNode,
  setErrorBorder,
  getTriggerNodeData,
  nodeHasErrorBorder,
  validateNodeAndResetBorder
};
