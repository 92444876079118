import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { atsStore, automationStore, lookupStore } from "stores";
import stepUpdateRecordIcon from "assets/img/automation/step-updaterecord.svg";
import SubmitButton from "./SubmitButton";
import { RappidNodeType, RappidText } from "helpers/Constants";
import Select from 'react-select';
import { FormGroup, Input, Label } from "reactstrap";
import { Enum } from "helpers";
import { listCountry } from "assets/json/countries"
import HeaderForm from "./HeaderForm";
import { toJS } from "mobx";
import { EditRappidNode } from "components/Rappid/RappidCanvasFunctions";
import { AutomationType, TriggerType, UpdateRecordNoteType, UpdateRecordOwnerType } from "helpers/enum";
import { validateNodeAndResetBorder } from "components/Rappid/RappidCanvasFunctions";

const UpdateRecordForm = observer(() => {
  const { isJobScopeDisabled, isUseLegacyAutomation } = atsStore;
  const { formUpdateRecord, isCopiedTemplate, automationType, triggerList, triggerType, automationState } = automationStore;
  const { currentWorkTypes, candidateStatuses, candidateNoteStatuses, clientStatuses, clientNoteStatuses, applicationStatuses } = lookupStore;
  const isPlacementOrJobBasedTrigger = [Enum.TriggerType.PlacementBased, Enum.TriggerType.JobBased].includes(triggerType);
  const isPlacementBasedTrigger = [Enum.TriggerType.PlacementBased].includes(triggerType);
  const optionStatusAllSelected = { statusId: 0, name: 'Any' };
  const noteStatuses = automationType === Enum.AutomationType.Candidate ? candidateNoteStatuses.filter(x => x.name !== optionStatusAllSelected.name) : clientNoteStatuses.filter(x => x.name !== optionStatusAllSelected.name);
  const ownerDefaultValue = isPlacementOrJobBasedTrigger ? (formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.CandidateNote ? Enum.OwnerTypeCandidate[2] : Enum.OwnerTypeCandidate[1]) : Enum.OwnerTypeCandidate[0];
  const statuses = automationType === Enum.AutomationType.Candidate ? candidateStatuses.filter(x => x.name !== optionStatusAllSelected.name) : clientStatuses.filter(x => x.name !== optionStatusAllSelected.name); //remove unused option 8372
  const optionsUpdateRecord = automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate : Enum.OwnerTypeClient;
  const [updateRecordOption, setUpdateRecordOption] = useState(optionsUpdateRecord);
  const isDraft = automationState === Enum.AutomationStateType.Draft;

  let updateRecordOptions;
  if (triggerType === Enum.TriggerType.JobBased) {
    updateRecordOptions = automationType === Enum.AutomationType.Candidate ? Enum.JobAutomationUpdateRecordFirstColumn : Enum.AutomationClientUpdateRecordFirstColumn;
  } else {
    updateRecordOptions = automationType === Enum.AutomationType.Candidate ? Enum.AutomationUpdateRecordFirstColumn : Enum.AutomationClientUpdateRecordFirstColumn;
  }

  const isShowPlacementAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.PlacementJobApplicationReceived
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate
      ].includes(item.firstColumn.id));
    }
  }

  const isShowApplicationAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [].includes(item.firstColumn.id));
    }
  }

  const isShowJobAdditionalNotes = () => {
    if (automationType === Enum.AutomationType.Candidate) {
      return triggerList.some(item => [
        Enum.TriggerList.CandidatePlacementStartDate,
        Enum.TriggerList.CandidatePlacementEndDate,
        Enum.TriggerList.CandidatePlacementCreatedDate,
        Enum.TriggerList.ApplicationStatus,
        Enum.TriggerList.JobStatus,
        Enum.TriggerList.JobJobApplicationReceived,
        Enum.TriggerList.PlacementJobApplicationReceived,
        Enum.TriggerList.InterviewDate
      ].includes(item.firstColumn.id));
    } else {
      return triggerList.some(item => [
        Enum.TriggerListClient.ClientPlacementCreatedDate,
        Enum.TriggerListClient.ClientPlacementStartDate,
        Enum.TriggerListClient.ClientPlacementEndDate,
        Enum.TriggerListClient.JobStatus,
        Enum.TriggerListClient.ClientJobInterviewDate
      ].includes(item.firstColumn.id));
    }
  }

  useEffect(() => {
    Promise.all([
      atsStore.getCompanyInfo(),
      automationType === Enum.AutomationType.Candidate ? lookupStore.getCandidateNoteStatuses() : lookupStore.getClientNoteStatuses(),
      automationType === Enum.AutomationType.Candidate ? lookupStore.getCandidateStatuses() : lookupStore.getClientStatuses(),
      automationType === Enum.AutomationType.Candidate ? lookupStore.getApplicationStatusesList() : null
    ]).then(() => {
      const cloneUpdateRecordForm = { ...automationStore.formUpdateRecord };
      if (isCopiedTemplate === true && typeof cloneUpdateRecordForm.isUpdatedFromCopiedTemplate === "undefined") {
        switch (cloneUpdateRecordForm.recordField) {
          case Enum.UpdateRecordList.CurrentWorkType:
            cloneUpdateRecordForm.workTypeId = "";
            cloneUpdateRecordForm.workType = "";
            break;
          case Enum.UpdateRecordList.Status:
          case Enum.UpdateRecordList.ApplicationStatus:
            cloneUpdateRecordForm.status = "";
            cloneUpdateRecordForm.statusId = "";
            break;
          case Enum.UpdateRecordList.AddNote:
            cloneUpdateRecordForm.note = "";
            cloneUpdateRecordForm.noteStatus = "";
            cloneUpdateRecordForm.isUpdateAdditionalNotes = true;
            cloneUpdateRecordForm.additionalNoteType = Enum.UpdateRecordNoteType.CandidateNote;
            break;
          default:
            break;
        }

        automationStore.setFormUpdateRecord(cloneUpdateRecordForm);
        EditRappidNode(RappidNodeType.UpdateRecord, cloneUpdateRecordForm.name, "", "", "", cloneUpdateRecordForm, false, true);
      }

      if (!isPlacementOrJobBasedTrigger || triggerList.length === 0) {
        const filters = new Set([UpdateRecordOwnerType.JobOwner, UpdateRecordOwnerType.PlacementRecruiter]);

        // convert to
        const ownerType = formUpdateRecord.ownerType === UpdateRecordOwnerType.PlacementRecruiter || formUpdateRecord.ownerType === UpdateRecordOwnerType.JobOwner
            ? ownerDefaultValue.value
            : formUpdateRecord.ownerType;

        // convert the additional note to candidate note
        const additionalNoteType = automationStore.formUpdateRecord.additionalNoteType !== Enum.UpdateRecordNoteType.CandidateNote
          ? UpdateRecordNoteType.CandidateNote
          : formUpdateRecord.additionalNoteType;

        automationStore.setFormUpdateRecord({ 
          ...formUpdateRecord, 
          additionalNoteType: additionalNoteType,
          ownerType: ownerType
        }, true);

        setUpdateRecordOption(optionsUpdateRecord.filter(item => !filters.has(item.value)));
      }

      if (formUpdateRecord.isFromOwner === false || formUpdateRecord.ownerType === null) {
        automationStore.setFormUpdateRecord({ 
          ...formUpdateRecord, 
          isFromOwner: true, 
          ownerType: Enum.UpdateRecordOwnerType.AdminUser 
        });
      }

      if (isPlacementOrJobBasedTrigger) {
        const filters = new Set();

        if (triggerType !== TriggerType.PlacementBased) {
          filters.add(UpdateRecordOwnerType.PlacementRecruiter);

          const ownerType = formUpdateRecord.ownerType === UpdateRecordOwnerType.PlacementRecruiter
            ? ownerDefaultValue.value
            : formUpdateRecord.ownerType;

          const additionalNoteType = formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.PlacementNote
            ? Enum.UpdateRecordNoteType.JobNote
            : formUpdateRecord.additionalNoteType;

          automationStore.setFormUpdateRecord({
            ...formUpdateRecord,
            ownerType: ownerType,
            isUpdateAdditionalNotes: true,
            additionalNoteType: additionalNoteType
          }, true);
        }

        if (automationStore.formUpdateRecord.additionalNoteType ?? false !== Enum.UpdateRecordNoteType.CandidateNote) {
          filters.add(UpdateRecordOwnerType.NoUser);
        }
  
        setUpdateRecordOption(optionsUpdateRecord.filter(item => !filters.has(item.value)));
      }      
    });

    return function cleanup() {
      automationStore.resetFormUpdateRecord();
    }
    // eslint-disable-next-line
  }, []);

  const isShowAdditionalNotes = () => !isJobScopeDisabled && !isUseLegacyAutomation && isShowJobAdditionalNotes();

  const getFilteredOwnerOptions = () => {
    const filters = isPlacementOrJobBasedTrigger && triggerType !== TriggerType.PlacementBased
      ? [Enum.UpdateRecordOwnerType.PlacementRecruiter]
      : !isPlacementOrJobBasedTrigger
      ? [Enum.UpdateRecordOwnerType.JobOwner, Enum.UpdateRecordOwnerType.PlacementRecruiter]
      : [];

    return updateRecordOption.filter(item => !filters.includes(item.value))
  }

  console.debug("Update Record Form Data: ", toJS(formUpdateRecord));
  const isEmptyCandidateNoteStatus = lookupStore.candidateNoteStatuses.length === 0;
  const isEmptyClientNoteStatus = lookupStore.clientNoteStatuses.length === 0;
  const isEmptyNoteStatus = automationType === Enum.AutomationType.Candidate ? isEmptyCandidateNoteStatus : isEmptyClientNoteStatus;

  const isDisableButton = () => {
    switch (formUpdateRecord.recordField) {
      case Enum.UpdateRecordList.Postcode:
        return formUpdateRecord.postcode ? false : true;
      case Enum.UpdateRecordList.CurrentWorkType:
        return formUpdateRecord.workType ? false : true;
      case Enum.UpdateRecordList.Country:
        return formUpdateRecord.country ? false : true;
      case Enum.UpdateRecordList.Status:
        return formUpdateRecord.status ? false : true;
      case Enum.UpdateRecordList.AddNote:
        return formUpdateRecord.noteStatus && formUpdateRecord.note && !isEmptyNoteStatus ? false : true;
      case Enum.UpdateRecordList.ApplicationStatus:
        return formUpdateRecord.applicationStatus ? false : true;
      default:
        return true;
    }
  };

  return (
    <>
      <HeaderForm
        automationHeaderId="form-step-updaterecord"
        automationHeaderImgSrc={stepUpdateRecordIcon}
        automationHeaderImgAlt="icon-updaterecord"
        automationHeaderTextStep={RappidText.UpdateRecordStep}
      />
      <FormGroup className="step-body-text">
        <span className="mb-3"><b><Label className="text-white">Choose field to update</Label></b></span>
        <Select
          className="automation-form-dropdown-multi"
          id="automation-updaterecord-recordfield"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={updateRecordOptions}
          isDisabled={!isDraft}
          onChange={e => {
            let data = {};
            data.recordField = e.id;
            data.name = e.name;
            if (e.id === Enum.UpdateRecordList.AddNote) {
              data.isFromOwner = true;
              data.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[3].value : Enum.OwnerTypeClient[3].value;
              if (triggerList.length > 0 && isPlacementOrJobBasedTrigger) {
                data.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[1].value : Enum.OwnerTypeClient[1].value
              }
              if (triggerList.length > 0 && isShowAdditionalNotes) {
                data.isUpdateAdditionalNotes = true;
                data.additionalNoteType = isPlacementOrJobBasedTrigger ? (isPlacementBasedTrigger ? Enum.UpdateRecordNoteType.PlacementNote : Enum.UpdateRecordNoteType.JobNote) : Enum.UpdateRecordNoteType.CandidateNote;
                data.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[3].value : Enum.OwnerTypeClient[3].value;

                if (isPlacementOrJobBasedTrigger) {
                  data.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[1].value : Enum.OwnerTypeClient[1].value;
                  setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser))
                }
              }
            } else if (!!formUpdateRecord.isFromOwner || !!formUpdateRecord.ownerType) {
              data.isFromOwner = false;
              data.ownerType = null;
            }
            automationStore.setFormUpdateRecord(data);
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={updateRecordOptions.find(x => x.id === formUpdateRecord.recordField)}
        />
      </FormGroup>

      {formUpdateRecord.recordField === Enum.UpdateRecordList.Postcode &&
        <FormGroup className="step-body-text">
          <Input
            type="number"
            id="automation-updaterecord-postcode"
            className="rounded-pill automation-alert-input"
            disabled={!isDraft}
            onChange={e => {
              let data = { ...formUpdateRecord };
              data.postcode = e.target.value;
              automationStore.setFormUpdateRecord(data);
            }}
            value={String(formUpdateRecord.postcode)}
          />
        </FormGroup>
      }

      {formUpdateRecord.recordField === Enum.UpdateRecordList.CurrentWorkType &&
        <Select
          id="automation-updaterecord-worktype"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={currentWorkTypes}
          isDisabled={!isDraft}
          onChange={e => {
            let data = { ...formUpdateRecord };
            data.workTypeId = e.workTypeId;
            data.workType = e.name;
            automationStore.setFormUpdateRecord(data);
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.workTypeId}
          value={currentWorkTypes.find(x => x.workTypeId === formUpdateRecord.workTypeId) || 0}
        />
      }

      {formUpdateRecord.recordField === Enum.UpdateRecordList.Country &&
        <Select
          id="automation-updaterecord-country"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={listCountry}
          isDisabled={!isDraft}
          onChange={e => {
            let data = { ...formUpdateRecord };
            data.country = e.name;
            data.countryCode = e.code;
            automationStore.setFormUpdateRecord(data);
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          value={listCountry.find(x => x.name === formUpdateRecord.country) || ""}
        />
      }

      {formUpdateRecord.recordField === Enum.UpdateRecordList.Status &&
        <Select
          id="automation-updaterecord-status"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={statuses}
          isDisabled={!isDraft}
          onChange={e => {
            let data = { ...formUpdateRecord };
            data.statusId = e.statusId;
            data.status = e.name;
            automationStore.setFormUpdateRecord(data);
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.statusId}
          value={statuses.find(x => x.statusId === formUpdateRecord.statusId) || 0}
        />
      }

      {formUpdateRecord.recordField === Enum.UpdateRecordList.AddNote &&
        <>
          <Select
            id="automation-updaterecord-notetype"
            classNamePrefix="automation-dropdown"
            placeholder="- Select note type -"
            options={noteStatuses}
            isDisabled={!isDraft}
            onChange={e => {
              let data = { ...formUpdateRecord };
              data.noteStatus = e.name;
              automationStore.setFormUpdateRecord(data);
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={noteStatuses.find(x => x.name === formUpdateRecord.noteStatus) || ""}
          />
          <FormGroup className="step-body-text">
            <Input
              type="textarea"
              className="automation-form-dropdown-multi textarea-form"
              id="automation-updaterecord-note-text"
              disabled={!isDraft}
              onChange={e => {
                let data = { ...formUpdateRecord };
                data.note = e.target.value;
                automationStore.setFormUpdateRecord(data);
              }}
              value={formUpdateRecord.note}
            />
          </FormGroup>
          <FormGroup className="step-body-text">
            <Label check htmlFor={`automation-updaterecord-from-owner`} className="mb-2">
              Add note from
            </Label>
            {triggerList.length > 0 && isPlacementOrJobBasedTrigger ? (
              <Select
                id="automation-updaterecord-from-ownertype"
                className={`automation-email-template col-md-12 px-0 mb-2 ${!formUpdateRecord.isFromOwner && `automation-disable-dropdown`}`}
                classNamePrefix="automation-dropdown"
                placeholder="Select Owner"
                menuPosition="fixed"
                menuPlacement="auto"
                menuShouldBlockScroll={true}
                isDisabled={!isDraft}
                onChange={(e) => {
                  automationStore.setFormUpdateRecord({ ...formUpdateRecord, ownerType: e.value });
                }}
                options={updateRecordOption}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                value={
                  formUpdateRecord.ownerType
                    ? automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate.find(x => x.value === formUpdateRecord.ownerType) : Enum.OwnerTypeClient.find(x => x.value === formUpdateRecord.ownerType)
                    : ownerDefaultValue
                }
              />)
              : (<Select
                id="automation-updaterecord-from-ownertype"
                className={`automation-email-template col-md-12 px-0 mb-2 ${!formUpdateRecord.isFromOwner && `automation-disable-dropdown`}`}
                classNamePrefix="automation-dropdown"
                placeholder="Select Owner"
                menuPosition="fixed"
                menuPlacement="auto"
                menuShouldBlockScroll={true}
                isDisabled={!isDraft}
                onChange={(e) => {
                  automationStore.setFormUpdateRecord({ ...formUpdateRecord, ownerType: e.value });
                }}
                options={updateRecordOption.filter(item => ![Enum.UpdateRecordOwnerType.JobOwner].includes(item.value))}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                value={
                  formUpdateRecord.ownerType
                    ? automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate.find(x => x.value === formUpdateRecord.ownerType) : Enum.OwnerTypeClient.find(x => x.value === formUpdateRecord.ownerType)
                    : ownerDefaultValue
                }
              />)
            }
          </FormGroup>
          {isShowAdditionalNotes() &&
            <FormGroup className="step-body-text">
              <span className="mb-3"><b><Label className="text-white">Add note to</Label></b></span><br />
              <Input
                type="checkbox"
                id="automation-update-to-candidate-note"
                className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                checked={formUpdateRecord.isUpdateAdditionalNotes && formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.CandidateNote}
                disabled={!isDraft}
                onChange={(e) => {
                  automationStore.setFormUpdateRecord({
                    ...formUpdateRecord,
                    isUpdateAdditionalNotes: e.target.checked,
                    additionalNoteType: Enum.UpdateRecordNoteType.CandidateNote
                  });
                  setUpdateRecordOption(optionsUpdateRecord)
                }}
              />
              <Label check htmlFor={`automation-update-to-candidate-note`} className="mb-1 mr-3">
                {automationType === Enum.AutomationType.Candidate ? 'Candidate' : 'Contact'}
              </Label>
              {isShowPlacementAdditionalNotes() &&
                <>
                  <Input
                    type="checkbox"
                    id="automation-update-to-placement-note"
                    className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                    checked={formUpdateRecord.isUpdateAdditionalNotes && formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.PlacementNote}
                    disabled={!isDraft}
                    onChange={(e) => {
                      automationStore.setFormUpdateRecord({
                        ...formUpdateRecord,
                        isUpdateAdditionalNotes: e.target.checked,
                        additionalNoteType: Enum.UpdateRecordNoteType.PlacementNote,
                        ownerType: formUpdateRecord.isFromOwner ? (formUpdateRecord.ownerType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.JobOwner : formUpdateRecord.ownerType) : null
                      });
                      setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser))
                    }}
                  />
                  <Label check htmlFor={`automation-update-to-placement-note`} className="mb-1 mr-3">
                    Placement
                  </Label>
                </>
              }
              <Input
                type="checkbox"
                id="automation-update-to-job-note"
                className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                checked={formUpdateRecord.isUpdateAdditionalNotes && formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.JobNote}
                disabled={!isDraft}
                onChange={(e) => {
                  automationStore.setFormUpdateRecord({
                    ...formUpdateRecord,
                    isUpdateAdditionalNotes: e.target.checked,
                    additionalNoteType: Enum.UpdateRecordNoteType.JobNote,
                    ownerType: formUpdateRecord.isFromOwner ? (formUpdateRecord.ownerType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.JobOwner : formUpdateRecord.ownerType) : null
                  });
                  setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser))
                }}
              />
              <Label check htmlFor={`automation-update-to-job-note`} className="mb-1 mr-3">
                Job
              </Label>
              {isShowApplicationAdditionalNotes() &&
                <>
                  <Input
                    type="checkbox"
                    id="automation-update-to-application-note"
                    className="automation-time-variable-checkbox-days custom-checkbox-pull-left"
                    checked={formUpdateRecord.isUpdateAdditionalNotes && formUpdateRecord.additionalNoteType === Enum.UpdateRecordNoteType.ApplicationNote}
                    disabled={!isDraft}
                    onChange={(e) => {
                      automationStore.setFormUpdateRecord({
                        ...formUpdateRecord,
                        isUpdateAdditionalNotes: e.target.checked,
                        additionalNoteType: Enum.UpdateRecordNoteType.ApplicationNote,
                        ownerType: formUpdateRecord.isFromOwner ? (formUpdateRecord.ownerType === Enum.UpdateRecordOwnerType.NoUser ? Enum.UpdateRecordOwnerType.JobOwner : formUpdateRecord.ownerType) : null
                      });
                      setUpdateRecordOption(optionsUpdateRecord.filter(item => item.value !== Enum.UpdateRecordOwnerType.NoUser))
                    }}
                  />
                  <Label check htmlFor={`automation-update-to-application-note`} className="mb-1 mr-3">
                    Application
                  </Label>
                </>
              }
            </FormGroup>
          }
        </>
      }

      {formUpdateRecord.recordField === Enum.UpdateRecordList.ApplicationStatus &&
        <Select
          id="automation-updaterecord-status"
          classNamePrefix="automation-dropdown"
          placeholder="- Select -"
          options={applicationStatuses}
          isDisabled={!isDraft}
          onChange={e => {
            let data = { ...formUpdateRecord };
            data.applicationStatusId = e.statusId;
            data.applicationStatus = e.name;
            automationStore.setFormUpdateRecord(data);
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.statusId}
          value={applicationStatuses.find(x => x.statusId === formUpdateRecord.applicationStatusId)}
        />
      }

      {isDraft &&
        <SubmitButton
          type={RappidNodeType.UpdateRecord}
          text={setUpdateRecordNodeTextLine1(formUpdateRecord)}
          text2={setUpdateRecordNodeTextLine2(formUpdateRecord)}
          roiData={formUpdateRecord}
          validateConditions={() => validateNodeAndResetBorder()}
          isDisabled={isDisableButton()} />
      }
    </>
  );
});

export const setUpdateRecordNodeTextLine1 = (formUpdateRecord) => {
  switch (formUpdateRecord.recordField) {
    case Enum.UpdateRecordList.Status:
      return "Status updated to: " + formUpdateRecord.status;
    case Enum.UpdateRecordList.AddNote:
      return "Note type: " + formUpdateRecord.noteStatus;
    case Enum.UpdateRecordList.ApplicationStatus:
      return "Status updated to: " + formUpdateRecord.applicationStatus;
    default:
      return "";
  }
}

export const setUpdateRecordNodeTextLine2 = (formUpdateRecord) => formUpdateRecord.recordField === Enum.UpdateRecordList.AddNote ? "Note added: " + formUpdateRecord.note : "";

export default UpdateRecordForm;
