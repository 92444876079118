import { observable, action } from "mobx";
import { ApiUrl, ApiService, Enum, Constants } from "helpers";
import Alert from 'react-s-alert';
//TODO: Remove this HeaderTabName no longer exists in the frontend because of new UI we don't have tabs anymore..
import { HeaderTabName, EmailSenderDefault, FilterModelPageName, RappidNode } from "helpers/Constants";
import { AutomationGridColumnName, AutomationGridFieldName } from "views/Automation/AutomationGrid/Existing/AutomationExistingConstant";
import { AutomationTemplateGridColumnName, AutomationTemplateGridFieldName } from "views/Automation/AutomationGrid/Template/AutomationTemplateConstant";
import { rappidStore } from "stores";
import { CheckIfFirstTriggerCanvasIsEmpty, loadAutomationIntoCanvas, ConvertToArrayStructure, ConvertTriggerListForBackEnd, getTriggerNodeData } from "components/Rappid/RappidCanvasFunctions";
import { AutomationIfElseFirstColumn, AutomationTriggerList, AutomationTriggerListClient, AutomationIfElseFirstColumnClient, AutomationSpecificDays, UpdateRecordNoteType } from "helpers/enum";
import moment from "moment";
import moment_timezone from 'moment-timezone';
import { RappidNodeType } from "helpers/Constants";
import { FormatDate } from "helpers";

const automationStore = observable(
  {
    automationType: 1, // for candidate / client type, set it from reset , submit, get
    automationId: null,
    automationList: [],
    filteredAutomationList: [],
    automationTemplateList: [],
    filteredAutomationTemplateList: [],
    automationTab: HeaderTabName.TAB_AUTOMATION_STORY,
    previousAutomationName: "",
    automationName: "",
    isUpdatedAutomationName: false,
    automationDescription: "",
    automationAudiences: [],
    automationAudiencesIds: [],
    selectedAutomationAudienceIds: [],  // use to store selected audience before user click "add/update" button in trigger form
    automationAlertUserIds: [], // for automation alert user list
    automationDiagramStructure: "", // JSON data from rappid canvas
    automationArrayStructure: [],
    automationDelayArrayStructure: [],
    automationState: 4, // always set as draft for initial load
    automationTriggerListWithField: [],
    isAutomationTemplate: false,
    isUseAutomationTemplate: false,
    isManageTabDisabled: false,
    isStoryTabDisabled: false,
    isFinaliseTabDisabled: true,
    showSideBar: false,
    showSideBarForm: false,
    isSidebarFormUpdate: false,
    triggerType: null,
    originalTriggerType: null,
    hasChangedFromOriginal: false,
    isSpecificTimezone: true,
    timeZoneDays: ["1", "2", "3", "4", "5"],
    timeZoneFromHours: new Date(moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })),
    timeZoneToHours: new Date(moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 })),
    timeZoneArea: moment_timezone.tz.guess(),
    timeZoneGmt: moment().format('Z'),
    /* Frontend Sent off is false, but in backend is true */
    isAutomationReEntryRestriction: true,
    automationReEntryRestrictionWeek: 0,
    automationReEntryRestrictionDay: 0,
    automationReEntryRestrictionHour: 1,
    /* Disabled until pbi loop show */
    isAutomationLooping: false,
    automationLoopingWeek: 0,
    automationLoopingDay: 1,
    automationLoopingHour: 0,
    loopingType: 1,
    isAutomationIndefinite: false,
    isOpenConfirmationEdit: false,
    isAutomationFormEdited: false,
    isFromSelectAudience: false,
    tagOptions: [],
    isCopiedTemplate: false,
    listSenderDomain: [],
    companyDomainDefault: {},
    automationOnClear: false,
    isOpenNewTab: false,
    filterAutomationsModel: {},
    filterAutomationTemplatesModel: {},
    filterSearchText: "",
    helpUrl: null,
    isTemplateValid: false,
    isLegacyAlert: false,
    automationUsers: [],
    automationUserIds: [],
    selectedAutomationUserIds: [],
    automationOffices: [],
    automationOfficeIds: [],
    selectedAutomationOfficeIds: [],
    automationGroups: [],
    automationGroupIds: [],
    selectedAutomationGroupIds: [],
    includeRecentNoteOwner: false,
    selectedIncludeRecentNoteOwner: false,
    userGroupOfficeDeleted: false,

    // DEFINITIONS
    defaultFormIfElse: {
      firstColumn: {
        id: null,
        name: "",
        options: AutomationIfElseFirstColumn,
      },
      secondColumn: {
        id: null,
        name: "",
        options: []
      },
      thirdColumn: {
        ids: [],
        names: "",
        options: []
      },
      operator: {
        id: null,
        name: ""
      },
      isShowWithinLast: false,
      withinLast: {
        weeks: 1,
        days: 0,
        hours: 0,
        minutes: 0
      },
      withinLastOptions: {
        showWeeks: true,
        showDays: true,
        showHours: true,
        showMinutes: true
      }
    },

    defaultFormIfElseClient: {
      firstColumn: {
        id: null,
        name: "",
        options: AutomationIfElseFirstColumnClient,
      },
      secondColumn: {
        id: null,
        name: "",
        options: []
      },
      thirdColumn: {
        ids: [],
        names: "",
        options: []
      },
      operator: {
        id: null,
        name: ""
      },
      isShowWithinLast: false,
      withinLast: {
        weeks: 1,
        days: 0,
        hours: 0,
        minutes: 0,
      },
      withinLastOptions: {
        showWeeks: true,
        showDays: true,
        showHours: true,
        showMinutes: true
      }
    },
    defaultFormTrigger: {
      firstColumn: {
        id: null,
        name: "",
        options: AutomationTriggerList,
      },
      secondColumn: {},
      thirdColumn: {},
      operator: {
        id: null,
        name: ""
      },
    },
    defaultFormTriggerClient: {
      firstColumn: {
        id: null,
        name: "",
        options: AutomationTriggerListClient,
      },
      secondColumn: {},
      thirdColumn: {},
      operator: {
        id: null,
        name: ""
      },
    },
    formTrigger: [],
    formIfElse: [],
    formEmail: {
      emailCategoryId: 0,
      emailCategoryName: "- Select category -",
      emailSourceId: 0,
      emailSourceName: "- Select source -",
      oriEmailCampaignId: 0,
      oriEmailCampaignName: "",
      emailCampaignId: 0,
      emailCampaignName: "- Select email -",
      senderName: EmailSenderDefault.Name,
      senderEmail: EmailSenderDefault.Email,
      senderDomain: "",
      companyDomainId: null,
      subject: "",
      isFromOwner: true,
      ownerType: null,
      isSpecificTime: false,
      specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
      startHour: null,
      convertedStartHour: null,
      isUpdateRecord: true,
      updateRecordType: 1,
      isUpdateAdditionalNotes: true,
      additionalNoteType: UpdateRecordNoteType.CandidateNote
    },
    formDelay: {
      roiData: {
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        delayDays: 0,
        delayStartDate: null,
        delayDate: null,
        previousDelayId: "",
        previousTotalMinutesTime: 0,
        isDelayCondition: false,
        delayCondition: {},
        isFirstDelay: false
      }
    },
    formFolder: {
      folderCategoryId: 0,
      folderCategoryName: "- Select -",
      folderIds: [],
      folderNames: "",
      updateFolderTo: [],
    },
    currentPreviousDelayId: "",
    formAlert: {
      userIds: [],
      emailCampaignId: null,
      name: "",
      subject: "",
      message: "",
      isIncludeViewButton: false,
      isSendToOwner: false,
      isSendToPlacementOwner: false,
      isSendToPlacementRecruiter: false,
      isSpecificTime: false,
      specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
      startHour: null,
      convertedStartHour: null
    },
    formUpdateRecord: {},
    formText: {
      title: "",
      message: "",
      messageTextOnly: "",
      countryCode: "au",
      isSpecificTime: false,
      specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
      startHour: null,
      isUpdateRecord: true,
      updateRecordType: 1,
      convertedStartHour: null,
      isUpdateAdditionalNotes: true,
      additionalNoteType: UpdateRecordNoteType.CandidateNote
    },
    sidebarFormType: "",
    tempSidebarFormTypeObject: null,
    isAutomationFormDirty: false, // whether form validation passes or fails
    isAutomationSidebarFormDirty: {
      isDirty: false,
      isActive: false
    }, // for trigger form & if else form only
    triggerList: [],
    triggerMatchNumber: 0,
    isTriggerMatchNumberLoading: false,
    activeGrid: HeaderTabName.TAB_AUTOMATION_GRID_TEMPLATE,
    isTriggerPlacementStartDate: false,
    isTriggerPlacementEndDate: false,
    isAudienceDirty: false,

    gridColumn: Object.values(AutomationGridFieldName).map((item, index) => {
      const headerName = Object.values(AutomationGridColumnName);
      return {
        id: index,
        colId: item,
        field: item,
        headerName: headerName[index],
        width: item === AutomationGridFieldName.AutomationAction ? 400 : item === AutomationGridFieldName.AutomationName || item === AutomationGridFieldName.AutomationDescription ? 350 : 200,
        hide: false,
        sort: item === AutomationGridFieldName.AutomationCreatedDate ? 'asc' : null,
        order: index++
      }
    }),

    gridColumnTemplate: Object.values(AutomationTemplateGridFieldName).map((item, index) => {
      const headerName = Object.values(AutomationTemplateGridColumnName);
      return {
        id: index,
        colId: item,
        field: item,
        headerName: headerName[index],
        width: item === AutomationTemplateGridColumnName.AutomationAction ? 350 : item === AutomationTemplateGridColumnName.AutomationName || item === AutomationGridFieldName.AutomationDescription ? 300 : 200,
        hide: false,
        sort: item === AutomationTemplateGridColumnName.AutomationCreatedDate ? 'asc' : null,
        order: index++
      }
    }),

    // UTILITIES
    clearSidebar() {
      this.showSideBar = false;
      this.showSideBarForm = false;
      this.isSidebarFormUpdate = false;
    },

    convertStringTimeToObject(stringTime) {
      const time = stringTime.split(":");
      const resultTime = {
        hours: time[0],
        minutes: time[1],
        seconds: time[2]
      };
      return resultTime
    },

    convertTimeToString(time) {
      let timeString = time?.getHours() + ":" + time?.getMinutes();
      return time && this.isSpecificTimezone ? timeString : null
    },

    convertTimeZoneGmt(stringGmt) {
      let gmt = stringGmt.substring(0, 3) + "0".substring(1);
      let gmtReplace = gmt[1] === "0" ? gmt.replace(gmt.substring(1, 2), "") : gmt;
      return parseInt(gmtReplace)
    },

    convertStepTimeToString(time) {
      return time?.getHours() + ":" + time?.getMinutes();
    },

    resetSidebar() {
      this.setShowSideBar(false);
      this.setShowSideBarForm(false);
      this.resetSidebarForm();
      this.setSidebarFormType("");
    },

    resetAutomation() {
      this.automationType = 1;
      this.automationTab = HeaderTabName.TAB_AUTOMATION_STORY;
      this.automationId = null;
      this.previousAutomationName = "";
      this.automationName = "";
      this.isUpdatedAutomationName = false;
      this.automationDescription = "";
      this.automationAudiences = [];
      this.automationAudiencesIds = [];
      this.selectedAutomationAudienceIds = [];
      this.automationAlertUserIds = [];
      this.automationDiagramStructure = "";
      this.automationArrayStructure = [];
      this.automationDelayArrayStructure = [];
      this.automationState = Enum.AutomationStateType.Draft;
      this.isManageTabDisabled = false;
      this.isStoryTabDisabled = false;
      this.isFinaliseTabDisabled = true;
      this.isAutomationFormDirty = false;
      this.resetAutomationSidebarFormDirty();
      this.triggerList.length = 0;
      this.isSpecificTimezone = true;
      this.isOpenConfirmationEdit = false;
      this.isAutomationFormEdited = false;
      this.isAutomationTemplate = false;
      this.isUseAutomationTemplate = false;
      this.isFromSelectAudience = false;
      this.isCopiedTemplate = false;
      this.resetTimeVariableFields();
      this.isAutomationReEntryRestriction = true;
      this.automationReEntryRestrictionWeek = 0;
      this.automationReEntryRestrictionDay = 0;
      this.automationReEntryRestrictionHour = 1;
      this.isAutomationLooping = false;
      this.automationLoopingHour = 0;
      this.automationLoopingDay = 1;
      this.automationLoopingWeek = 0;
      this.resetSidebarForm();
      this.clearSidebar();
      this.tagOptions = [];
      this.currentPreviousDelayId = "";
      this.loopingType = 1;
      this.automationOnClear = false;
      this.isTriggerPlacementStartDate = false;
      this.isTriggerPlacementEndDate = false;
      this.isAudienceDirty = false;
      this.isAutomationIndefinite = false;
      this.helpUrl = null;
      this.isTemplateValid = false;
      this.isLegacyAlert = false;
      this.automationUsers = [];
      this.automationUserIds = [];
      this.selectedAutomationUserIds = [];
      this.automationOffices = [];
      this.automationOfficeIds = [];
      this.selectedAutomationOfficeIds = [];
      this.automationGroups = [];
      this.automationGroupIds = [];
      this.selectedAutomationGroupIds = [];
      this.includeRecentNoteOwner = false;
      this.userGroupOfficeDeleted = false;
    },

    getIfElseFormDataForNode(nodeId) {
      const { graph } = rappidStore;
      const node = graph.getCell(nodeId);
      if (node?.attributes?.roiNodeType === RappidNodeType.IfElse) {
        return node.attributes.roiData[0] || [];
      }
      return null;
    },
    

    resetTimeVariableFields() {
      this.timeZoneDays = ["1", "2", "3", "4", "5"];
      this.timeZoneFromHours = new Date(moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }));
      this.timeZoneToHours = new Date(moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 }));
      this.timeZoneArea = moment_timezone.tz.guess();
      this.timeZoneGmt = moment().format('Z');
    },

    updateGridColumn(gridColumn) {
      this.gridColumn = gridColumn;
    },

    //SET
    setCampaignsSearchFilterString(searchString) {
      const { FormatDateUtc } = FormatDate;
      //TODO: possible extract to helpers for searching strings, or extract to store helpers
      function ApplySearchStringIfUpdatedDateExists(item, searchString) {
        return !!item.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
      }

      function removeStateStringAutomationIsPrefixIfItExists(text) {
        var prefix = "Automation is ";
        return (text.indexOf(prefix) === 0) ? text.slice(prefix.length) : text;
      }

      this.filterSearchText = searchString;

      this.filteredAutomationList = this.automationList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item, searchString) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        removeStateStringAutomationIsPrefixIfItExists(item.stateString).toLowerCase().startsWith(searchString.toLowerCase()) ||
        item.category.toLowerCase().includes(searchString.toLowerCase()) ||
        item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    setCampaignTemplatesSearchFilterString(searchString) {
      const { FormatDateUtc } = FormatDate;
      //TODO: possible extract to helpers for searching strings, or extract to store helpers
      function ApplySearchStringIfUpdatedDateExists(item, searchString) {
        return !!item.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
      }

      this.filterSearchText = searchString;

      this.filteredAutomationTemplateList = this.automationTemplateList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item, searchString) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.description.toLowerCase().includes(searchString.toLowerCase()) ||
        item.category.toLowerCase().includes(searchString.toLowerCase()) ||
        item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    setIsTriggerPlacementStartDate(isTriggerPlacementStartDate) {
      this.isTriggerPlacementStartDate = isTriggerPlacementStartDate;
    },

    setIsTriggerPlacementEndDate(isTriggerPlacementEndDate) {
      this.isTriggerPlacementEndDate = isTriggerPlacementEndDate;
    },

    setActiveGrid(activeGrid) {
      this.activeGrid = activeGrid;
    },

    setAutomationsFilterModel(data) {
      this.filterAutomationsModel = data;
      sessionStorage.setItem(FilterModelPageName.CampaignsPage, JSON.stringify(data));
    },

    setAutomationsTemplateFilterModel(data) {
      this.filterAutomationTemplatesModel = data;
      sessionStorage.setItem(FilterModelPageName.CampaignsTemplatePage, JSON.stringify(data));
    },

    setAutomationOnClear(automationOnClear) { this.automationOnClear = automationOnClear; },

    setIsAudienceDirty(isAudienceDirty) { this.isAudienceDirty = isAudienceDirty; },

    setAutomationType(automationType) {
      this.automationType = automationType;
    },

    setIsAutomationTemplate(isAutomationTemplate) {
      this.isAutomationTemplate = isAutomationTemplate;
    },

    setIsUseAutomationTemplate(isUseAutomationTemplate) {
      this.isUseAutomationTemplate = isUseAutomationTemplate;
    },

    setTimeZoneFromHours(timeZoneFromHours) {
      this.timeZoneFromHours = timeZoneFromHours;
      this.isAutomationFormDirty = true;
    },

    setTimeZoneToHours(timeZoneToHours) {
      this.timeZoneToHours = timeZoneToHours;
      this.isAutomationFormDirty = true;
    },

    setIsSpecificTimezone(isSpecificTimezone) {
      this.isSpecificTimezone = isSpecificTimezone;
      if (!isSpecificTimezone) {
        this.resetTimeVariableFields();
      }
      this.isAutomationFormDirty = true;
    },

    setTimeZoneArea(timeZoneArea) {
      this.timeZoneArea = timeZoneArea;
      this.isAutomationFormDirty = true;
    },

    setTimeZoneGmt(timeZoneGmt) {
      this.timeZoneGmt = timeZoneGmt;
      this.isAutomationFormDirty = true;
    },

    setIsAutomationReEntryRestriction(isAutomationReEntryRestriction) {
      this.isAutomationReEntryRestriction = isAutomationReEntryRestriction;
      this.isAutomationFormDirty = true;
      this.resetAutomationReEntryRestriction();
    },

    resetAutomationReEntryRestriction() {
      this.automationReEntryRestrictionHour = 1;
      this.automationReEntryRestrictionDay = 0;
      this.automationReEntryRestrictionWeek = 0;
    },

    setAutomationReEntryRestrictionWeek(automationReEntryRestrictionWeek) {
      this.automationReEntryRestrictionWeek = automationReEntryRestrictionWeek;
      this.isAutomationFormDirty = true;
    },

    setAutomationReEntryRestrictionDay(automationReEntryRestrictionDay) {
      this.automationReEntryRestrictionDay = automationReEntryRestrictionDay;
      this.isAutomationFormDirty = true;
    },

    setAutomationReEntryRestrictionHour(automationReEntryRestrictionHour) {
      this.automationReEntryRestrictionHour = automationReEntryRestrictionHour;
      this.isAutomationFormDirty = true;
    },

    setIsAutomationIndefinite(isAutomationIndefinite) {
      this.isAutomationIndefinite = isAutomationIndefinite;
      this.isAutomationFormDirty = true;
      if (!isAutomationIndefinite) {
        this.resetLoopingForIndefinite();
      }
    },

    resetLoopingForIndefinite() {
      this.isAutomationLooping = false;
      this.automationLoopingHour = 0;
      this.automationLoopingDay = 1;
      this.automationLoopingWeek = 0;
    },

    setIsAutomationLooping(isAutomationLooping) {
      this.isAutomationLooping = isAutomationLooping;
      this.isAutomationFormDirty = true;
      this.resetAutomationLooping();
    },

    setLoopingType(loopingType) {
      this.loopingType = loopingType;
    },

    resetAutomationLooping() {
      this.automationLoopingHour = 0;
      this.automationLoopingDay = 1;
      this.automationLoopingWeek = 0;
    },

    setAutomationLoopingWeek(automationLoopingWeek) {
      this.automationLoopingWeek = automationLoopingWeek;
      this.isAutomationFormDirty = true;
    },

    setAutomationLoopingDay(automationLoopingDay) {
      this.automationLoopingDay = automationLoopingDay;
      this.isAutomationFormDirty = true;
    },

    setAutomationLoopingHour(automationLoopingHour) {
      this.automationLoopingHour = automationLoopingHour;
      this.isAutomationFormDirty = true;
    },

    clearAutomationReEntryRestrictionData() {
      if (CheckIfFirstTriggerCanvasIsEmpty() || (this.triggerType === Enum.TriggerType.PlacementBased && this.automationType === Enum.AutomationType.Client)) {
        this.isAutomationReEntryRestriction = true;
        this.automationReEntryRestrictionHour = 1;
        this.automationReEntryRestrictionDay = 0;
        this.automationReEntryRestrictionWeek = 0;
      }
    },

    clearAutomationLoopingData() {
      if (CheckIfFirstTriggerCanvasIsEmpty()) {
        this.isAutomationLooping = false;
        this.automationLoopingHour = 0;
        this.automationLoopingDay = 0;
        this.automationLoopingWeek = 0;
      }
    },

    setLoopingTypePlacementBased() {
      if (this.triggerType === Enum.TriggerType.PlacementBased && this.automationType === Enum.AutomationType.Client) {
        this.loopingType = Enum.AutomationLoopingType.Looping;
      }
    },

    // TODO: reapply the filtering when reloading 
    setAutomationList(automationList) {
      this.automationList = automationList;
      this.filteredAutomationList = automationList;
      this.filterAutomationsModel = {};
    },

    // TODO: reapply the filtering when reloading 
    setAutomationTemplateList(automationTemplateList) {
      this.automationTemplateList = automationTemplateList;
      this.filteredAutomationTemplateList = automationTemplateList;
      this.filterAutomationTemplatesModel = {};
    },

    setAutomationTab(automationTab) {
      this.automationTab = automationTab;
      switch (automationTab) {
        case HeaderTabName.TAB_AUTOMATION_STORY:
          this.isStoryTabDisabled = false;
          this.isFinaliseTabDisabled = true;
          break;
        case HeaderTabName.TAB_AUTOMATION_FINALISE:
          this.isStoryTabDisabled = true;
          this.isFinaliseTabDisabled = false;
          break;
        default:
          this.isStoryTabDisabled = false;
          this.isFinaliseTabDisabled = true;
          break;
      }
    },

    setAutomationId(automationId) {
      this.automationId = automationId;
    },

    setAutomationName(automationName) {
      this.automationName = automationName;
      this.isAutomationFormDirty = true;
    },

    setPreviousAutomationName(previousAutomationName) {
      this.previousAutomationName = previousAutomationName;
    },

    setIsUpdatedAutomationName(isUpdatedAutomationName) {
      this.isUpdatedAutomationName = isUpdatedAutomationName;
    },

    setAutomationDescription(automationDescription) {
      this.automationDescription = automationDescription;
      this.isAutomationFormDirty = true;
    },

    setAutomationAudiences(automationAudiences) {
      this.automationAudiences = automationAudiences;
      this.isAutomationFormDirty = true;
    },

    setAutomationAudiencesIds(automationAudiencesIds) {
      this.automationAudiencesIds = automationAudiencesIds;
      this.isAutomationFormDirty = true;
    },

    setSelectedAutomationAudienceIds(selectedAutomationAudienceIds) {
      this.selectedAutomationAudienceIds = selectedAutomationAudienceIds;
    },

    setAutomationAlertUserIds(automationAlertUserIds) {
      this.automationAlertUserIds = automationAlertUserIds;
    },

    pushAutomationAudience(automationAudience, isFormDirty = true) {
      this.automationAudiences.push(automationAudience);
      if (isFormDirty)
        this.isAutomationFormDirty = true;
    },

    pushAutomationAudienceId(automationAudienceId) {
      this.automationAudiencesIds.push(automationAudienceId);
      this.isAutomationFormDirty = true;
    },

    pushSelectedAutomationAudienceIds(selectedAutomationAudienceIds) {
      this.selectedAutomationAudienceIds.push(selectedAutomationAudienceIds);
      this.isAutomationFormDirty = true;
    },

    pushAutomationUser(automationUser, isFormDirty = true) {
      this.automationUsers.push(automationUser);
      if (isFormDirty)
        this.isAutomationFormDirty = true;
    },

    pushAutomationUserId(automationUserId) {
      this.automationUserIds.push(automationUserId);
      this.isAutomationFormDirty = true;
    },

    pushSelectedAutomationUserIds(selectedAutomationUserIds) {
      this.selectedAutomationUserIds.push(selectedAutomationUserIds);
      this.isAutomationFormDirty = true;
    },

    pushAutomationOffice(automationOffice, isFormDirty = true) {
      this.automationOffices.push(automationOffice);
      if (isFormDirty)
        this.isAutomationFormDirty = true;
    },

    pushAutomationOfficeId(automationOfficeId) {
      this.automationOfficeIds.push(automationOfficeId);
      this.isAutomationFormDirty = true;
    },

    pushSelectedAutomationOfficeIds(selectedAutomationOfficeIds) {
      this.selectedAutomationOfficeIds.push(selectedAutomationOfficeIds);
      this.isAutomationFormDirty = true;
    },

    pushAutomationGroup(automationGroup, isFormDirty = true) {
      this.automationGroups.push(automationGroup);
      if (isFormDirty)
        this.isAutomationFormDirty = true;
    },

    pushAutomationGroupId(automationGroupId) {
      this.automationGroupIds.push(automationGroupId);
      this.isAutomationFormDirty = true;
    },

    pushSelectedAutomationGroupIds(selectedAutomationGroupIds) {
      this.selectedAutomationGroupIds.push(selectedAutomationGroupIds);
      this.isAutomationFormDirty = true;
    },

    setAutomationDiagramStructure(automationDiagramStructure) {
      this.automationDiagramStructure = automationDiagramStructure;
      this.isAutomationFormDirty = true;
    },

    setAutomationArrayStructure(automationArrayStructure) {
      this.automationArrayStructure = automationArrayStructure;
      this.isAutomationFormDirty = true;
    },

    setAutomationDelayArrayStructure(automationDelayArrayStructure) {
      this.automationDelayArrayStructure = automationDelayArrayStructure;
    },

    setAutomationState(automationState) {
      this.automationState = automationState;
      this.isAutomationFormDirty = true;
    },

    setAutomationTriggerListCustomField(automationTriggerListWithField) {
      this.automationTriggerListWithField = [...automationTriggerListWithField];
    },

    setIsManageTabDisabled(isManageTabDisabled) {
      this.isManageTabDisabled = isManageTabDisabled;
    },

    setIsStoryTabDisabled(isStoryTabDisabled) {
      this.isStoryTabDisabled = isStoryTabDisabled;
    },

    setIsFinaliseTabDisabled(isFinaliseTabDisabled) {
      this.isFinaliseTabDisabled = isFinaliseTabDisabled;
    },

    setShowSideBar(showSideBar) {
      this.showSideBar = showSideBar;
    },

    setShowSideBarForm(showSideBarForm) {
      this.showSideBarForm = showSideBarForm;
    },

    setIsFromSelectAudience(isFromSelectAudience) {
      this.isFromSelectAudience = isFromSelectAudience;
    },

    setTimeZoneDays(timeZoneDays) {
      this.timeZoneDays = [...timeZoneDays];
      this.isAutomationFormDirty = true;
    },

    setTriggerList(triggerList, change = true) {
      this.triggerList = [...triggerList];
      this.isAutomationFormDirty = true;

      if (change) {
        this.resetUpdateRecordFormDataBasedOnTrigger();
      }
    },

    setAutomationUsers(automationUsers) {
      this.automationUsers = automationUsers;
      this.isAutomationFormDirty = true;
    },

    setAutomationUserIds(automationUserIds) {
      this.automationUserIds = automationUserIds;
      this.isAutomationFormDirty = true;
    },

    setSelectedAutomationUserIds(selectedAutomationUserIds) {
      this.selectedAutomationUserIds = selectedAutomationUserIds;
    },

    setAutomationOffices(automationOffices) {
      this.automationOffices = automationOffices;
      this.isAutomationFormDirty = true;
    },

    setAutomationOfficeIds(automationOfficeIds) {
      this.automationOfficeIds = automationOfficeIds;
      this.isAutomationFormDirty = true;
    },

    setSelectedAutomationOfficeIds(selectedAutomationOfficeIds) {
      this.selectedAutomationOfficeIds = selectedAutomationOfficeIds;
    },

    setAutomationGroups(automationGroups) {
      this.automationGroups = automationGroups;
      this.isAutomationFormDirty = true;
    },

    setAutomationGroupIds(automationGroupIds) {
      this.automationGroupIds = automationGroupIds;
      this.isAutomationFormDirty = true;
    },

    setSelectedAutomationGroupIds(selectedAutomationGroupIds) {
      this.selectedAutomationGroupIds = selectedAutomationGroupIds;
    },

    setIncludeRecentNoteOwner(includeRecentNoteOwner) {
      this.includeRecentNoteOwner = includeRecentNoteOwner;
      this.selectedIncludeRecentNoteOwner = includeRecentNoteOwner;
    },

    setSelectedIncludeRecentNoteOwner(selectedIncludeRecentNoteOwner) {
      this.selectedIncludeRecentNoteOwner = selectedIncludeRecentNoteOwner;
    },

    setUserGroupOfficeDeleted(userGroupOfficeDeleted) {
      this.userGroupOfficeDeleted = userGroupOfficeDeleted;
    },

    isShowUpdateRecordPlacementNote() {
      if (this.automationType === Enum.AutomationType.Candidate) {
        return automationStore.triggerList.some(item => [
          Enum.TriggerList.CandidatePlacementStartDate,
          Enum.TriggerList.CandidatePlacementEndDate,
          Enum.TriggerList.CandidatePlacementCreatedDate,
          Enum.TriggerList.PlacementJobApplicationReceived
        ].includes(item.firstColumn.id));
      } else {
        return automationStore.triggerList.some(item => [
          Enum.TriggerListClient.ClientPlacementCreatedDate,
          Enum.TriggerListClient.ClientPlacementStartDate,
          Enum.TriggerListClient.ClientPlacementEndDate
        ].includes(item.firstColumn.id))
      }
    },

    isShowUpdateRecordApplicationNote() {
      if (this.automationType === Enum.AutomationType.Candidate) {
        return automationStore.triggerList.some(item => [
          Enum.TriggerList.JobApplicationReceived,
          Enum.TriggerList.CandidatePlacementStartDate,
          Enum.TriggerList.CandidatePlacementEndDate,
          Enum.TriggerList.CandidatePlacementCreatedDate,
          Enum.TriggerList.ApplicationStatus,
          Enum.TriggerList.JobStatus,
          Enum.TriggerList.JobJobApplicationReceived,
          Enum.TriggerList.PlacementJobApplicationReceived,
          Enum.TriggerList.InterviewDate
        ].includes(item.firstColumn.id));
      } else {
        return automationStore.triggerList.some(item => [].includes(item.firstColumn.id));
      }
    },

    isShowUpdateRecordJobNote() {
      if (this.automationType === Enum.AutomationType.Candidate) {
        return automationStore.triggerList.some(item => [
          Enum.TriggerList.CandidatePlacementStartDate,
          Enum.TriggerList.CandidatePlacementEndDate,
          Enum.TriggerList.CandidatePlacementCreatedDate,
          Enum.TriggerList.PlacementJobApplicationReceived,
          Enum.TriggerList.JobApplicationReceived,
          Enum.TriggerList.CandidatePlacementStartDate,
          Enum.TriggerList.CandidatePlacementEndDate,
          Enum.TriggerList.CandidatePlacementCreatedDate,
          Enum.TriggerList.ApplicationStatus,
          Enum.TriggerList.JobStatus,
          Enum.TriggerList.JobJobApplicationReceived,
          Enum.TriggerList.PlacementJobApplicationReceived,
          Enum.TriggerList.InterviewDate
        ].includes(item.firstColumn.id));
      } else {
        return automationStore.triggerList.some(item => [
          Enum.TriggerListClient.ClientPlacementCreatedDate,
          Enum.TriggerListClient.ClientPlacementStartDate,
          Enum.TriggerListClient.ClientPlacementEndDate,
          Enum.TriggerListClient.JobStatus,
          Enum.TriggerListClient.ClientJobInterviewDate
        ].includes(item.firstColumn.id));
      }
    },

    isPlacementOrJobBasedTrigger() {
      return [Enum.TriggerType.PlacementBased, Enum.TriggerType.JobBased].includes(this.triggerType)
    },

    isPlacementBasedTrigger() {
      return [Enum.TriggerType.PlacementBased].includes(this.triggerType)
    },

    isJobBasedTrigger() {
      return [Enum.TriggerType.JobBased].includes(this.triggerType)
    },

    // TODO: check function, it assumes data is loaded in rappidStore.graph
    resetUpdateRecordFormDataBasedOnTrigger() {
      rappidStore.graph.attributes.cells.models.forEach(model => {
        if (model.attributes?.roiNodeType === RappidNodeType.UpdateRecord && model.attributes?.roiData?.recordField === Enum.UpdateRecordList.AddNote) {
          if (model.attributes.roiData.isFromOwner !== false && model.attributes.roiData.ownerType !== null) {
            if (this.triggerType === Enum.TriggerType.CandidateBased &&
              model.attributes.roiData.ownerType === Enum.UpdateRecordOwnerType.JobOwner) {
              model.attributes.roiData.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[3].value : Enum.OwnerTypeClient[0].value;
            } else if (
              (this.triggerType === Enum.TriggerType.PlacementBased || this.triggerType === Enum.TriggerType.JobBased) &&
              model.attributes.roiData.additionalNoteType !== Enum.UpdateRecordNoteType.CandidateNote &&
              model.attributes.roiData.ownerType === Enum.UpdateRecordOwnerType.NoUser) {
              model.attributes.roiData.ownerType = automationStore.automationType === Enum.AutomationType.Candidate ? Enum.OwnerTypeCandidate[1].value : Enum.OwnerTypeClient[1].value;
            }
          } else {
            model.attributes.roiData.isFromOwner = true;
            if (automationStore.automationType === Enum.AutomationType.Candidate) {
              model.attributes.roiData.ownerType = Enum.UpdateRecordOwnerType.NoUser;
            } else {
              model.attributes.roiData.ownerType = Enum.UpdateRecordOwnerType.AdminUser;
            }
          }

          if (model.attributes.roiData.isUpdateAdditionalNotes === true) {
            if (
              (model.attributes.roiData.additionalNoteType === Enum.UpdateRecordNoteType.PlacementNote && !this.isShowUpdateRecordPlacementNote()) ||
              (model.attributes.roiData.additionalNoteType === Enum.UpdateRecordNoteType.ApplicationNote && !this.isShowUpdateRecordApplicationNote()) ||
              (model.attributes.roiData.additionalNoteType === Enum.UpdateRecordNoteType.JobNote && !this.isShowUpdateRecordJobNote())) {
              model.attributes.roiData.isUpdateAdditionalNotes = false;
            }
          }
        }
      });
    },

    setFormTrigger(formTrigger, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formTrigger = [...formTrigger];
    },

    setFormIfElse(formIfElse, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formIfElse = [...formIfElse];
    },

    setFormEmail(formEmail, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formEmail = { ...formEmail };
    },

    setFormAlert(formAlert, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formAlert = { ...formAlert };
    },

    setFormDelay(formDelay, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formDelay = { ...formDelay };
    },

    setCurrentPreviousDelayId(currentPreviousDelayId) {
      this.currentPreviousDelayId = currentPreviousDelayId;
    },

    setFormUpdateRecord(formUpdateRecord, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formUpdateRecord = { ...formUpdateRecord };
    },

    setFormText(formText, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formText = { ...formText };
    },

    setFormFolder(formFolder, initialization = false) {
      if (!initialization) this.setAutomationSidebarFormToDirty();
      this.formFolder = { ...formFolder };
    },

    setTempSidebarFormTypeObject(sidebarFormType, setFormData) {

      if (!sidebarFormType) {
        this.tempSidebarFormTypeObject = null;
        return;
      }

      if (automationStore.isAutomationSidebarFormDirty.isDirty) {
        this.tempSidebarFormTypeObject = { sidebarFormType, setFormData };
        automationStore.setIsAutomationSidebarFormDirty({
          ...automationStore.isAutomationSidebarFormDirty,
          isActive: true
        });
      } else {
        this.resetSidebar();
        this.setShowSideBarForm(true);
        this.setSidebarFormType(sidebarFormType);
        setFormData && setFormData();
      }
    },

    setSidebarFormType(sidebarFormType) {
      this.sidebarFormType = sidebarFormType;
    },

    setIsSidebarFormUpdate(isSidebarFormUpdate) {
      this.isSidebarFormUpdate = isSidebarFormUpdate;
    },

    setIsAutomationFormDirty(isAutomationFormDirty) {
      this.isAutomationFormDirty = isAutomationFormDirty;
    },

    setIsAutomationSidebarFormDirty(isAutomationSidebarFormDirty) {
      this.isAutomationSidebarFormDirty = { ...isAutomationSidebarFormDirty };
    },

    setAutomationSidebarFormToDirty() {
      this.setIsAutomationSidebarFormDirty({
        ...this.isAutomationSidebarFormDirty,
        isDirty: true
      });
    },

    setAutomationSidebarFormToClean() {
      this.setIsAutomationSidebarFormDirty({
        ...this.isAutomationSidebarFormDirty,
        isDirty: false
      });
    },

    setIsOpenConfirmationEdit(isOpenConfirmationEdit) {
      this.isOpenConfirmationEdit = isOpenConfirmationEdit;
    },

    setIsAutomationFormEdited(isAutomationFormEdited) {
      this.isAutomationFormEdited = isAutomationFormEdited;
    },

    setTriggerType(triggerType) {
      // Store original trigger type if not set
      if (!this.originalTriggerType) {
        this.originalTriggerType = triggerType;
      } else if (this.triggerType !== triggerType) {
        // Mark as changed if we ever deviate from original
        this.hasChangedFromOriginal = true;
      }
      this.triggerType = triggerType;
    },

    hasTriggerChanged(newTrigger) {
      return this.hasChangedFromOriginal || 
      (this.originalTriggerType !== null && 
       this.originalTriggerType !== newTrigger);
    },

    setTriggerMatchNumber(triggerMatchNumber) {
      this.triggerMatchNumber = triggerMatchNumber;
    },

    setIsTriggerMatchNumberLoading(istriggerMatchNumberLoading) {
      this.isTriggerMatchNumberLoading = istriggerMatchNumberLoading;
    },

    setTagOptions(tagOptions, dirty = true) {
      this.tagOptions = [...tagOptions];
      this.isAutomationFormDirty = dirty;
    },

    setListSenderDomain(listSenderDomain) {
      this.listSenderDomain = [...listSenderDomain];
    },

    setCompanyDomainDefault(companyDomainDefault) {
      this.companyDomainDefault = companyDomainDefault;
    },

    setIsCopiedTemplate(isCopiedTemplate) {
      this.isCopiedTemplate = isCopiedTemplate;
      if (isCopiedTemplate)
        this.resetAutomationAudience();
    },

    setIsOpenNewTab(isOpenNewTab) {
      this.isOpenNewTab = isOpenNewTab;
    },

    setHelpUrl(helpUrl) {
      this.helpUrl = helpUrl;
    },

    setIsTemplateValid(isValid) {
      this.isTemplateValid = isValid;
    },

    setIsLegacyAlert(isLegacyAlert) {
      this.isLegacyAlert = isLegacyAlert;
    },

    resetAutomationSidebarFormDirty() {
      this.isAutomationSidebarFormDirty = { isActive: false, isDirty: false };
    },

    resetFormTrigger(isEmptyTriggerForm = false) {
      this.formTrigger.length = 0;
      this.triggerMatchNumber = 0;
      this.isTriggerMatchNumberLoading = false;
      // this.automationTriggerListWithField = [];
      if (isEmptyTriggerForm)
        this.formTrigger = [];
      else
        this.formTrigger = this.automationType === Enum.AutomationType.Candidate ? [{ ...this.defaultFormTrigger }] : [{ ...this.defaultFormTriggerClient }];
    },

    resetAutomationTriggerListCustomField() {
      this.automationTriggerListWithField = [];
    },

    resetFormIfElse() {
      this.formIfElse.length = 0;
      this.formIfElse = this.automationType === Enum.AutomationType.Candidate ? [{ ...this.defaultFormIfElse }] : [{ ...this.defaultFormIfElseClient }];
    },

    resetFormEmail() {
      this.formEmail = {
        emailCategoryId: 0,
        emailCategoryName: "- Select category -",
        emailSourceId: 0,
        emailSourceName: "- Select source -",
        oriEmailCampaignId: 0,
        oriEmailCampaignName: "",
        emailCampaignId: 0,
        emailCampaignName: "- Select email -",
        senderName: EmailSenderDefault.Name,
        senderEmail: EmailSenderDefault.Email,
        senderDomain: "",
        companyDomainId: null,
        subject: "",
        isFromOwner: this.isPlacementOrJobBasedTrigger() ? true : false,
        ownerType: this.isPlacementOrJobBasedTrigger() ? Enum.OwnerTypeCandidate[1].value : Enum.OwnerTypeCandidate[3].value,
        isSpecificTime: false,
        specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
        startHour: null,
        isUpdateRecord: true,
        updateRecordType: this.isPlacementOrJobBasedTrigger() ? Enum.UpdateRecordOwnerType.JobOwner : Enum.UpdateRecordOwnerType.NoUser,
        convertedStartHour: null,
        isUpdateAdditionalNotes: true,
        additionalNoteType: this.isPlacementOrJobBasedTrigger() ? (this.isPlacementBasedTrigger() ? UpdateRecordNoteType.PlacementNote : UpdateRecordNoteType.JobNote) : UpdateRecordNoteType.CandidateNote
      };
    },

    resetFormDelay() {
      this.formDelay = {
        roiData: {
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          delayDays: 0,
          delayStartDate: null,
          delayDate: null,
          previousDelayId: "",
          previousTotalMinutesTime: 0,
          isDelayCondition: false,
          delayCondition: {},
          isFirstDelay: false
        }
      }
    },

    resetFormAlert() {
      this.formAlert = {
        userIds: [],
        emailCampaignId: null,
        name: "",
        subject: "",
        message: "",
        isIncludeViewButton: false,
        isSendToOwner: false,
        isSendToPlacementOwner: false,
        isSendToPlacementRecruiter: false,
        isSpecificTime: false,
        specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
        startHour: null,
        convertedStartHour: null
      }
    },

    resetFormUpdateRecord() {
      this.formUpdateRecord = {};
    },

    resetFormText() {
      this.formText = {
        title: "",
        message: "",
        messageTextOnly: "",
        countryCode: "au",
        isSpecificTime: false,
        specificDays: [AutomationSpecificDays.Monday, AutomationSpecificDays.Tuesday, AutomationSpecificDays.Wednesday, AutomationSpecificDays.Thursday, AutomationSpecificDays.Friday],
        startHour: null,
        isUpdateRecord: true,
        updateRecordType: this.isPlacementOrJobBasedTrigger() ? Enum.UpdateRecordOwnerType.JobOwner : (this.automationType === Enum.AutomationType.Candidate ? Enum.UpdateRecordOwnerType.NoUser : Enum.UpdateRecordOwnerType.Recruiter),
        convertedStartHour: null,
        isUpdateAdditionalNotes: true,
        additionalNoteType: this.isPlacementOrJobBasedTrigger() ? (this.isPlacementBasedTrigger() ? UpdateRecordNoteType.PlacementNote : UpdateRecordNoteType.JobNote) : UpdateRecordNoteType.CandidateNote
      };
    },

    resetFormFolder() {
      this.formFolder = {
        folderCategoryId: 0,
        folderCategoryName: "- Select -",
        folderIds: [],
        folderNames: "",
        updateFolderTo: []
      };
    },

    resetAutomationAudience() {
      this.automationAudiences = [];
      this.automationAudiencesIds = [];
      this.selectedAutomationAudienceIds = [];
    },

    resetAutomationUsers() {
      this.automationUsers = [];
      this.automationUsersIds = [];
      this.selectedAutomationUserIds = [];
    },

    resetAutomationOffices() {
      this.automationOffices = [];
      this.automationOfficesIds = [];
      this.selectedAutomationOfficeIds = [];
    },

    resetAutomationGroups() {
      this.automationGroups = [];
      this.automationGroupsIds = [];
      this.selectedAutomationGroupIds = [];
    },

    resetSidebarForm(isEmptyTriggerForm = false) {
      this.resetFormEmail();
      this.resetFormDelay();
      this.resetFormTrigger(isEmptyTriggerForm);
      this.resetFormAlert();
      this.resetFormIfElse();
      this.resetFormUpdateRecord();
      this.resetFormText();
      this.setAutomationSidebarFormToClean();
      this.resetFormFolder();
      this.isSidebarFormUpdate = false;
    },

    resetAndHideSidebarForm() {
      this.setShowSideBar(false);
      this.setShowSideBarForm(false);
      this.resetSidebarForm();
      this.setIsAutomationFormDirty(false);
      this.setAutomationOnClear(false);
      this.setSidebarFormType("");
    },

    resetSelectedAutomationAudienceIds() {
      this.selectedAutomationAudienceIds = [...this.automationAudiencesIds];
    },

    resetSelectedAutomationUserIds() {
      this.selectedAutomationUserIds = [...this.automationUserIds];
    },

    resetSelectedAutomationOfficeIds() {
      this.selectedAutomationOfficeIds = [...this.automationOfficeIds];
    },

    resetSelectedAutomationGroupIds() {
      this.selectedAutomationGroupIds = [...this.automationGroupIds];
    },

    deleteAudience(audienceId) {
      this.automationAudiences = this.automationAudiences.filter(x => x.id !== audienceId);
      let index = this.automationAudiencesIds.indexOf(audienceId);
      if (index !== -1) {
        this.automationAudiencesIds.splice(index, 1);
      };

      this.setIsAutomationFormDirty(true);
    },

    deleteUser(userId) {
      this.automationUsers = this.automationUsers.filter(x => x.id !== userId);
      let index = this.automationUserIds.indexOf(userId);
      if (index !== -1) {
        this.automationUserIds.splice(index, 1);
      };

      this.setIsAutomationFormDirty(true);
    },

    deleteOffice(officeId) {
      this.automationOffices = this.automationOffices.filter(x => x.id !== officeId);
      let index = this.automationOfficeIds.indexOf(officeId);
      if (index !== -1) {
        this.automationOfficeIds.splice(index, 1);
      };

      this.setIsAutomationFormDirty(true);
    },

    deleteGroup(groupId) {
      this.automationGroups = this.automationGroups.filter(x => x.id !== groupId);
      let index = this.automationGroupIds.indexOf(groupId);
      if (index !== -1) {
        this.automationGroupIds.splice(index, 1);
      };

      this.setIsAutomationFormDirty(true);
    },

    deleteUserList(id) {
      this.automationAlertUserIds = this.automationAlertUserIds.filter(x => x.id !== id);
    },

    handlePlacementBasedAutomationSettings(triggerList) {
      const isSurveyScoreTrigger = JSON.parse(triggerList)[0]?.firstColumn?.name === "Survey score";
      
      if (this.triggerType === Enum.TriggerType.PlacementBased && !isSurveyScoreTrigger) {
        this.isAutomationReEntryRestriction = false;
      }
    },

    submit(isDraft = false) {
      this.setAutomationDiagramStructure(rappidStore.canvasJson);
      this.setAutomationArrayStructure(ConvertToArrayStructure());
      this.clearAutomationLoopingData();
      this.clearAutomationReEntryRestrictionData();
      this.setLoopingTypePlacementBased();
      this.handlePlacementBasedAutomationSettings(ConvertTriggerListForBackEnd());
      var isCopiedTemplate = false;
      if (isDraft)
        isCopiedTemplate = !automationStore.isTemplateValid;

      const data = {
        id: this.automationId,
        name: this.automationName,
        type: this.automationType,
        description: this.automationDescription,
        audiences: this.automationAudiencesIds,
        state: isDraft ? Enum.AutomationStateType.Draft : Enum.AutomationStateType.Active,
        diagramStructure: this.automationDiagramStructure,
        arrayStructure: this.automationArrayStructure,
        triggerList: ConvertTriggerListForBackEnd(),
        triggerType: this.triggerType,
        isSpecificTimezone: this.isSpecificTimezone,
        specificDays: this.isSpecificTimezone ? this.timeZoneDays.toString() : null,
        startHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneFromHours) : null,
        endHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneToHours) : null,
        timezone: this.isSpecificTimezone ? this.convertTimeZoneGmt(this.timeZoneGmt) : null,
        isAutomationReEntryRestriction: this.isAutomationReEntryRestriction,
        automationReEntryRestrictionWeek: this.automationReEntryRestrictionWeek ? this.automationReEntryRestrictionWeek : 0,
        automationReEntryRestrictionDay: this.automationReEntryRestrictionDay ? this.automationReEntryRestrictionDay : 0,
        automationReEntryRestrictionHour: this.automationReEntryRestrictionHour ? this.automationReEntryRestrictionHour : 0,
        isAutomationLooping: this.isAutomationLooping,
        automationLoopingWeek: this.automationLoopingWeek ? this.automationLoopingWeek : 0,
        automationLoopingDay: this.automationLoopingDay ? this.automationLoopingDay : 0,
        automationLoopingHour: this.automationLoopingHour ? this.automationLoopingHour : 0,
        loopingType: this.loopingType,
        isAutomationIndefinite: this.isAutomationIndefinite,
        category: JSON.stringify(this.tagOptions.map((item) => { return item["value"]; })),
        isCopiedTemplate: isCopiedTemplate,
        isLegacyAlert: this.isLegacyAlert,
        users: this.automationUserIds,
        groups: this.automationGroupIds,
        offices: this.automationOfficeIds,
        includeRecentNoteOwner: this.includeRecentNoteOwner
      };

      if (this.automationId && !isDraft && !this.isUseAutomationTemplate)
        return this.update(data);
      else if (this.automationId && isDraft && !this.isUseAutomationTemplate)
        return this.update(data);
      else
        return this.save(data);
    },

    save(data) {
      return (ApiService.add(ApiUrl.addAutomation, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { data: { result: { state = Enum.AutomationStateType.Active } = {} } = {} } = response;
              if (state === Enum.AutomationStateType.Draft) {
                this.setIsAutomationFormDirty(false);
                this.setIsUseAutomationTemplate(false);
                this.setAutomationData(response, false, false);
              }
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    update(data) {
      return (ApiService.edit(ApiUrl.updateAutomation, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { data: { result: { state = Enum.AutomationStateType.Active } = {} } = {} } = response;
              if (state === Enum.AutomationStateType.Draft) {
                this.setIsAutomationFormDirty(false);
                this.setAutomationData(response, false, false);
              }
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    //NEED TO CHANGE THE STATE ON THE CURRENT AUTOMATION set current automation 
    deactivateAutomation(id, state) {
      let params = {
        id: id,
        state: state
      };

      return (ApiService.edit(ApiUrl.setAutomationState, params).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (!this.automationId) {
                this.getAutomationList();
              } else {
                this.setIsOpenConfirmationEdit(false);    //unbeleivable this is coupled here
                this.setAutomationId(id);         // should not be needed?
                this.setAutomationState(state);   // should be a calulated value. 
              }
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    deleteAutomation(id) {
      return (ApiService.remove(ApiUrl.deleteAutomation, id).then(
        action(
          response => {
            this.getAutomationList();
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    deleteAutomationTemplate(id) {
      return (ApiService.remove(ApiUrl.deleteAutomationTemplates, id).then(
        action(
          response => {
            this.getAutomationList();
            this.getAutomationTemplateList();
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationDetail(id) {
      return (ApiService.getDetail(ApiUrl.getAutomationDetail, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setAutomationData(response, true);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationTemplateDetail(id) {
      return (ApiService.getDetail(ApiUrl.getAutomationTemplateDetail, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTemplateAutomationData(response);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationList() {
      return (ApiService.get(ApiUrl.getAutomations).then(
        action(
          response => {
            this.setAutomationList(response.data.result);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationTemplateList() {
      return (ApiService.get(ApiUrl.getAutomationTemplates).then(
        action(
          response => {
            this.setAutomationTemplateList(response.data.result);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getUsers() {
      return (ApiService.get(ApiUrl.getAllUser + "/?inc=true", '').then(
        action(
          response => {
            this.setAutomationAlertUserIds(response.data.result);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    checkDomainVerify() {
      return (ApiService.get(`${ApiUrl.getCompanyDomain}?isDefaultOnly=false&isVerifiedOnly=true`).then(
        action(
          response => {
            const { data } = response;
            if (data.statusCode === 200 && !data.isError && data.result.length > 0) {
              let isDomainSelected = false;
              this.setListSenderDomain(data.result.map((item) => {
                //compare sender domain from canvas with list sender domain from api
                if (this.formEmail.companyDomainId === item.id) {
                  isDomainSelected = true;
                }
                return {
                  label: `@${item.domainName}`,
                  value: item.id,
                }
              }))
              const defaultDomain = data.result.filter((item) => item.isDefault === true)[0];
              if (defaultDomain && (this.formEmail.senderDomain === "" || !isDomainSelected || !this.formEmail.companyDomainId)) {
                this.setFormEmail({ ...this.formEmail, senderDomain: `@${defaultDomain.domainName}`, companyDomainId: defaultDomain.id }, true);
              }
              return Promise.resolve(response);
            }
            Alert.error(Constants.ALERT_UNVALIDATED_DOMAIN)
            return Promise.reject(Constants.ALERT_UNVALIDATED_DOMAIN);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getCompanyDomainDefault() {
      return (ApiService.get(`${ApiUrl.getCompanyDomain}?isDefaultOnly=true&isVerifiedOnly=true`).then(
        action(
          response => {
            const { data } = response;
            if (data.statusCode === 200 && !data.isError && data.result.length > 0) {
              const defaultDomain = data.result.filter((item) => item.isDefault === true)[0];
              if (defaultDomain) {
                this.setCompanyDomainDefault({
                  ...this.companyDomainDefault,
                  senderDomain: `@${defaultDomain.domainName}`,
                  companyDomainId: defaultDomain.id
                });
              }

              return Promise.resolve(response);
            }

            Alert.error(Constants.ALERT_UNVALIDATED_DOMAIN);
            return Promise.reject(Constants.ALERT_UNVALIDATED_DOMAIN);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    setTriggerTypeByFirstColumnId(triggerId, automationType, triggerNumber) {
      if (automationType === Enum.AutomationType.Candidate) { // candidate scope
        if (triggerId === Enum.TriggerList.CandidatePlacementStartDate ||
          triggerId === Enum.TriggerList.CandidatePlacementEndDate ||
          triggerId === Enum.TriggerList.CandidatePlacementCreatedDate ||
          triggerId === Enum.TriggerList.PlacementJobApplicationReceived) {
          return Enum.TriggerType.PlacementBased
        } else if (triggerId === Enum.TriggerList.ApplicationStatus || triggerId === Enum.TriggerList.JobStatus || triggerId === Enum.TriggerList.InterviewDate || triggerId === Enum.TriggerList.JobJobApplicationReceived) {
          return Enum.TriggerType.JobBased
        } else if (triggerId === Enum.TriggerList.SurveyScore) {
          return triggerNumber
        } else {
          return Enum.TriggerType.CandidateBased
        }
      } else { //client scope
        if (triggerId === Enum.TriggerListClient.ClientPlacementStartDate || triggerId === Enum.TriggerListClient.ClientPlacementEndDate || triggerId === Enum.TriggerListClient.ClientPlacementCreatedDate) {
          return Enum.TriggerTypeClient.PlacementBased
        } else if (triggerId === Enum.TriggerListClient.JobStatus || triggerId === Enum.TriggerListClient.ClientJobInterviewDate) {
          return Enum.TriggerTypeClient.JobBased
        } else if (triggerId === Enum.TriggerListClient.SurveyScore) {
          return triggerNumber
        } else {
          return Enum.TriggerTypeClient.ClientBased
        }
      }
    },

    setTemplateAutomationData(response) {
      this.setAutomationData(response, false, true, true);
    },

    setAutomationData(response, isFormDirty = false, redrawCanvas = true, isTemplate = false) {
      const { id, name, description, arrayStructure, state, audiences, triggerList, isSpecificTimezone, specificDays,
        startHour, endHour, category, isCopiedTemplate, isAutomationLooping, automationLoopingWeek, automationLoopingHour, automationLoopingDay,
        isAutomationReEntryRestriction, automationReEntryRestrictionHour, automationReEntryRestrictionDay, automationReEntryRestrictionWeek,
        loopingType, audienceDirty, isAutomationIndefinite, helpUrl, isLegacyAlert, userJobAdders, groupJobAdders, officeJobAdders, includeRecentNoteOwner, userGroupOfficeDeleted } = response.data.result;

      var automationState = isTemplate ? Enum.AutomationStateType.Draft : state;

      this.setAutomationId(id);
      this.setAutomationName(name);
      this.setPreviousAutomationName(name);
      this.setAutomationDescription(description);
      this.setAutomationArrayStructure(arrayStructure);
      this.setAutomationState(automationState);
      this.setLoopingType(loopingType);
      this.setIsAudienceDirty(audienceDirty);
      this.setIncludeRecentNoteOwner(includeRecentNoteOwner);
      this.setUserGroupOfficeDeleted(userGroupOfficeDeleted);

      if (audiences) {
        if (audiences.length > 0) {
          this.setAutomationAudiencesIds([]);
          this.setSelectedAutomationAudienceIds([]);
          for (let i = 0; i < audiences.length; i++) {
            this.pushAutomationAudienceId(audiences[i].id);
            this.pushSelectedAutomationAudienceIds(audiences[i].id);
          }
        } else {
          this.setAutomationAudiencesIds([]);
          this.setSelectedAutomationAudienceIds([]);
        }
      }

      if (userJobAdders) {
        if (userJobAdders.length > 0) {
          this.setAutomationUserIds([]);
          this.setSelectedAutomationUserIds([]);
          for (let i = 0; i < userJobAdders.length; i++) {
            this.pushAutomationUserId(userJobAdders[i].ownerId);
            this.pushSelectedAutomationUserIds(userJobAdders[i].ownerId);
          }
        } else {
          this.setAutomationUserIds([]);
          this.setSelectedAutomationUserIds([]);
        }
      }

      if (groupJobAdders) {
        if (groupJobAdders.length > 0) {
          this.setAutomationGroupIds([]);
          this.setSelectedAutomationGroupIds([]);
          for (let i = 0; i < groupJobAdders.length; i++) {
            this.pushAutomationGroupId(groupJobAdders[i].groupId);
            this.pushSelectedAutomationGroupIds(groupJobAdders[i].groupId);
          }
        } else {
          this.setAutomationGroupIds([]);
          this.setSelectedAutomationGroupIds([]);
        }
      }

      if (officeJobAdders) {
        if (officeJobAdders.length > 0) {
          this.setAutomationOfficeIds([]);
          this.setSelectedAutomationOfficeIds([]);
          for (let i = 0; i < officeJobAdders.length; i++) {
            this.pushAutomationOfficeId(officeJobAdders[i].officeId);
            this.pushSelectedAutomationOfficeIds(officeJobAdders[i].officeId);
          }
        } else {
          this.setAutomationOfficeIds([]);
          this.setSelectedAutomationOfficeIds([]);
        }
      }

      //set trigger list 
      const trigger = JSON.parse(triggerList);
      if (trigger.length > 0) {
        const triggerId = trigger[0].firstColumn.id;
        const triggerNumber = trigger[0].triggerNumber || 1;
        this.setFormTrigger(trigger);
        //set trigger type by automation type and first column id
        const triggerTypeByTriggerId = this.setTriggerTypeByFirstColumnId(triggerId, this.automationType, triggerNumber);
        this.setTriggerType(triggerTypeByTriggerId);
        this.setTriggerList(trigger, false);

        //setFormTrigger sets automation to dirty and so needs resetting
        this.setAutomationSidebarFormToClean();
      }

      this.setIsSpecificTimezone(isSpecificTimezone);
      if (isSpecificTimezone) {
        this.setTimeZoneDays(specificDays?.split(","));
        const fromHours = this.convertStringTimeToObject(startHour);
        const endHours = this.convertStringTimeToObject(endHour);
        this.setTimeZoneFromHours(new Date(moment().set({ hour: fromHours.hours, minute: fromHours.minutes, second: 0, millisecond: 0 })))
        this.setTimeZoneToHours(new Date(moment().set({ hour: endHours.hours, minute: endHours.minutes, second: 0, millisecond: 0 })))
      }

      if (category !== undefined) {
        this.setTagOptions(JSON.parse(category).map((item) => {
          return {
            label: item,
            value: item,
            isFixed: (this.automationType === Enum.AutomationType.Candidate && item === Enum.AutomationTypeText.Candidate) || (this.automationType === Enum.AutomationType.Client && item === Enum.AutomationTypeText.Contact),
          }
        }));
      }

      this.setIsCopiedTemplate(isCopiedTemplate);
      this.setIsAutomationReEntryRestriction(isAutomationReEntryRestriction);
      this.setAutomationReEntryRestrictionHour(automationReEntryRestrictionHour);
      this.setAutomationReEntryRestrictionDay(automationReEntryRestrictionDay);
      this.setAutomationReEntryRestrictionWeek(automationReEntryRestrictionWeek);
      this.setIsAutomationLooping(isAutomationLooping !== undefined && isAutomationLooping);
      this.setAutomationLoopingHour(automationLoopingHour);
      this.setAutomationLoopingDay(automationLoopingDay);
      this.setAutomationLoopingWeek(automationLoopingWeek);
      this.setIsAutomationIndefinite(isAutomationIndefinite);
      this.setIsAutomationFormDirty(isFormDirty);
      this.setHelpUrl(helpUrl);
      this.setIsLegacyAlert(isLegacyAlert);

      if (isCopiedTemplate) {
        this.setIsTemplateValid(false);
      } else {
        this.setIsTemplateValid(true);
      }

      if (redrawCanvas)
        loadAutomationIntoCanvas(arrayStructure, triggerList, automationState, redrawCanvas);
    },

    getIsNameInUse(isTemplate, name) {
      const param = `?name=${name}`;
      const url = isTemplate ? ApiUrl.getIsTemplateNameInUse : ApiUrl.getIsNameInUse;
      return (
        ApiService.get(url + param, "", 'Failed to get Candidates data').then(
          action(
            response => {
              if (response && response.status === 200 && response.data && !response.data.isError) {
                return Promise.resolve(response.data);
              } else {
                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
              }
            })));
    },

    postSaveAsNew(id, name) {
      const data = {
        id: id,
        name: name,
      };
      const url = ApiUrl.postSaveAsNew;
      return (ApiService.post(url, data).then(
        action(
          response => {
            if (!response || response.status !== 200 || !response.data || response.data.isError) {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ));
    },

    saveAsTemplate(isSaveToTemplate = false, name) {
      this.setAutomationDiagramStructure(rappidStore.canvasJson);
      this.setAutomationArrayStructure(ConvertToArrayStructure());
      this.clearAutomationLoopingData();
      this.clearAutomationReEntryRestrictionData();
      this.setLoopingTypePlacementBased();
      this.handlePlacementBasedAutomationSettings(ConvertTriggerListForBackEnd());

      const data = {
        id: this.automationId,
        name: name,
        type: this.automationType,
        description: this.automationDescription,
        audiences: this.automationAudiencesIds,
        diagramStructure: this.automationDiagramStructure,
        arrayStructure: this.automationArrayStructure,
        triggerList: ConvertTriggerListForBackEnd(), // probably not needed as we loaded it from the backend...
        triggerType: this.triggerType,
        isSpecificTimezone: this.isSpecificTimezone,
        specificDays: this.isSpecificTimezone ? this.timeZoneDays.toString() : null,
        startHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneFromHours) : null,
        endHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneToHours) : null,
        timezone: this.isSpecificTimezone ? this.convertTimeZoneGmt(this.timeZoneGmt) : null,
        isAutomationReEntryRestriction: this.isAutomationReEntryRestriction,
        automationReEntryRestrictionWeek: this.automationReEntryRestrictionWeek ? this.automationReEntryRestrictionWeek : 0,
        automationReEntryRestrictionDay: this.automationReEntryRestrictionDay ? this.automationReEntryRestrictionDay : 0,
        automationReEntryRestrictionHour: this.automationReEntryRestrictionHour ? this.automationReEntryRestrictionHour : 0,
        isAutomationLooping: this.isAutomationLooping,
        automationLoopingWeek: this.automationLoopingWeek ? this.automationLoopingWeek : 0,
        automationLoopingDay: this.automationLoopingDay ? this.automationLoopingDay : 0,
        automationLoopingHour: this.automationLoopingHour ? this.automationLoopingHour : 0,
        loopingType: this.loopingType,
        category: JSON.stringify(this.tagOptions.map((item) => {
          return item["value"];
        })),
        isLegacyAlert: this.isLegacyAlert,
        users: this.automationUserIds,
        groups: this.automationGroupIds,
        offices: this.automationOfficeIds,
        includeRecentNoteOwner: this.includeRecentNoteOwner
      };
      const url = isSaveToTemplate ? ApiUrl.automationSaveAsTemplate : ApiUrl.automationSaveAsExisting;
      return (ApiService.post(url, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              // do nothing?
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ));
    },

    updateTemplate() {
      this.setAutomationArrayStructure(ConvertToArrayStructure());
      this.clearAutomationLoopingData();
      this.clearAutomationReEntryRestrictionData();
      this.setLoopingTypePlacementBased();
      this.handlePlacementBasedAutomationSettings(ConvertTriggerListForBackEnd());
      const data = {
        id: this.automationId,
        name: this.automationName,
        type: this.automationType,
        description: this.automationDescription,
        audiences: this.automationAudiencesIds,
        diagramStructure: this.automationDiagramStructure,
        arrayStructure: this.automationArrayStructure,
        triggerList: ConvertTriggerListForBackEnd(),
        triggerType: this.triggerType,
        isSpecificTimezone: this.isSpecificTimezone,
        specificDays: this.isSpecificTimezone ? this.timeZoneDays.toString() : null,
        startHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneFromHours) : null,
        endHour: this.isSpecificTimezone ? this.convertTimeToString(this.timeZoneToHours) : null,
        timezone: this.isSpecificTimezone ? this.convertTimeZoneGmt(this.timeZoneGmt) : null,
        isAutomationReEntryRestriction: this.isAutomationReEntryRestriction,
        automationReEntryRestrictionWeek: this.automationReEntryRestrictionWeek ? this.automationReEntryRestrictionWeek : 0,
        automationReEntryRestrictionDay: this.automationReEntryRestrictionDay ? this.automationReEntryRestrictionDay : 0,
        automationReEntryRestrictionHour: this.automationReEntryRestrictionHour ? this.automationReEntryRestrictionHour : 0,
        isAutomationLooping: this.isAutomationLooping,
        automationLoopingWeek: this.automationLoopingWeek ? this.automationLoopingWeek : 0,
        automationLoopingDay: this.automationLoopingDay ? this.automationLoopingDay : 0,
        automationLoopingHour: this.automationLoopingHour ? this.automationLoopingHour : 0,
        loopingType: this.loopingType,
        isAutomationIndefinite: this.isAutomationIndefinite,
        category: JSON.stringify(this.tagOptions.map((item) => {
          return item["value"];
        })),
        users: this.automationUserIds,
        groups: this.automationGroupIds,
        offices: this.automationOfficeIds,
        includeRecentNoteOwner: this.includeRecentNoteOwner
      };

      return (ApiService.edit(ApiUrl.updateAutomationTemplate, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTemplateAutomationData(response);
              this.setIsAutomationFormDirty(false);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    getTriggerMatchesNumber(signal) {
      const data = {
        triggerList: JSON.stringify(this.formTrigger),
        automationType: this.automationType,
        audienceIds: this.selectedAutomationAudienceIds,
        userIds: this.selectedAutomationUserIds,
        groupIds: this.selectedAutomationGroupIds,
        officeIds: this.selectedAutomationOfficeIds,
        includeRecentNoteOwner: this.selectedIncludeRecentNoteOwner,
      };
      this.setIsTriggerMatchNumberLoading(true);

      return (ApiService.postWithSignal(ApiUrl.getTriggerMatchesNumber, data, signal).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setTriggerMatchNumber(response.data.result);
              this.setIsTriggerMatchNumberLoading(false);
            } else {
              this.setTriggerMatchNumber(0);
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }

            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            this.setTriggerMatchNumber(0);
            return Promise.reject(err);
          }
        )
      ));
    },

    toggleShowToRecruiter(id, isShowToRecruiter) {
      const data = {
        id,
        isShowToRecruiter
      };

      return (ApiService.edit(ApiUrl.automationSetShowToRecruiter, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.getAutomationTemplateList()
            } else {
              Alert.error(response.data.message || Constants.ALERT_SOMETHING_WENT_WRONG);
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationAudienceList() {
      const url = this.automationType === Enum.AutomationType.Candidate ? ApiUrl.getAutomationAudienceList : ApiUrl.getAutomationAudienceClientList;
      return (ApiService.get(url).then(
        action(
          response => {
            this.automationAudiences = [];
            response.data.result.forEach(item => {
              const audience = {
                id: item.id,
                name: item.audienceName,
                label: `${item.audienceName} (${item.totalMembers})`
              };
              this.pushAutomationAudience(audience, false);
            });
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationUserList() {
      const url = ApiUrl.getUserJobAdders;
      return (ApiService.get(url).then(
        action(
          response => {
            this.automationUsers = [];
            response.data.result.forEach(item => {
              const user = {
                id: item.ownerId,
                name: `${item.firstName} ${item.lastName}`,
                label: `${item.firstName} ${item.lastName}`
              };
              this.pushAutomationUser(user, false);
            });
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationOfficeList() {
      const url = ApiUrl.getOfficeJobAdders;
      return (ApiService.get(url).then(
        action(
          response => {
            this.automationOffices = [];
            response.data.result.forEach(item => {
              const office = {
                id: item.officeId,
                name: item.name,
                label: `${item.name}`
              };
              this.pushAutomationOffice(office, false);
            });
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getAutomationGroupList() {
      const url = ApiUrl.getGroupJobAdders;
      return (ApiService.get(url).then(
        action(
          response => {
            this.automationGroups = [];
            response.data.result.forEach(item => {
              const group = {
                id: item.groupId,
                name: item.name,
                label: `${item.name}`
              };
              this.pushAutomationGroup(group, false);
            });
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    isAutomationUpdatedFromTemplate() {
      const { graph } = rappidStore;
      const nodes = graph.attributes.cells.models;
      var isValid = true;
      nodes.forEach(node => {
        const roiData = node.attributes.roiData;

        if (node.attributes.type === RappidNode.RoiCustomTrigger || node.attributes.type === RappidNodeType.IfElse) {
          var parsedRoiData = roiData;

          if (Object.keys(parsedRoiData).length < 1) {
            isValid = false;
            return;
          }

          if (!Array.isArray(parsedRoiData)) {
            parsedRoiData = JSON.parse(roiData);
          }

          parsedRoiData.forEach(function (data) {
            if (data.isUpdatedFromCopiedTemplate !== true) isValid = false;
          });

        } else if (node.attributes.type === RappidNode.RoiCustomNode || node.attributes.type === RappidNode.RoiCustomDelayNode) {
          if (roiData.isUpdatedFromCopiedTemplate !== true) isValid = false;
        } else {
          return;
        }
      });

      this.setIsTemplateValid(isValid);
    }

    
  }
);

export default automationStore;
